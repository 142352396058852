/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	IAction,
	IActionApiMap,
	IApi,
	IApiEndpoint,
	IApiMap,
	IApplication,
	IComponent,
	IColumn,
	ITable,
	IFolder,
	IResource,
	IScreenCreator,
	IValidation,
} from 'src/@types/app';
import {
	ActionTypeEnum,
	ApiBodyType,
	ApiMethods,
	ApiParamTypeEnum,
	ComponentComplexEnum,
	ComponentSimpleEnum,
	ComponentTypeEnum,
	DatabaseColumnEnum,
	DatabaseMaskEnum,
	ResourceTypeEnum,
	ValidationTypeEnum,
} from 'src/@types/enums';
import {v4 as uuidV4} from 'uuid';
import Components, {DataInputComponent} from 'src/shared/components';
import {formatColumnLabelName, upperFirstWord} from './text-methods';
import {removeDiacritics} from './removeDiacritics';
import {
	faAdd,
	faClose,
	faEdit,
	faSearch,
	faTrash,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import {
	dotnetFormatScreenName,
	dotnetSchemaName,
} from 'src/shared/engine-back/core/dotnet/core/dotnet-formatter';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {ResourceApi} from 'src/shared/repositories/resource-api';

const getEndpointRef = (type: string, schema?: string, tableName?: string) =>
	`${type}_${schema ?? 'default'}_${tableName}`;

const getApiRef = (schema?: string, databaseName?: string) =>
	`API__${schema ?? 'default'}__${databaseName}`;

const endpointTypes = ['Incluir', 'Alterar', 'Remover', 'OData'];
const endpointTypesRefresh = ['Incluir', 'Alterar', 'OData'];

export const TransformToScreen = (application: IApplication): IScreenCreator => {
	const resources: IResource[] = [];

	application.database?.tables?.forEach(table => {
		const resourceName = formatScreenName(table.name ?? '');

		resources.push({
			ref: uuidV4().toString(),
			name: resourceName,
			type: ResourceTypeEnum.Page,
			menu: {
				use: true,
				name: resourceName,
			},
			widgets: transformWidgets(
				table.columns ?? [],
				application,
				resourceName,
				table.schema ?? '',
				application.database?.tables,
				table.ref ?? '',
			),
			useDefaultLayout: true,
			isLogin: false,
			isRoot: false,
			selected: false,
			databaseTableRef: table.ref,
			path: table.schema ? `/${dotnetSchemaName(table.schema!)}` : '',
		});
	});

	const folders: IFolder[] = [
		...(new Set(application.database?.tables?.map(x => x.schema)) as any),
	].map((name: string) => ({
		menu: {
			use: true,
			name: dotnetSchemaName(name),
		},
		name: dotnetSchemaName(name),
		path: '',
		ref: uuidV4().toString(),
	}));

	return {
		resources,
		folders,
		api: application.apis ?? [],
	};
};

export const UpdateTransformToScreen = async (application: IApplication): Promise<IApplication> => {
	const resources: IResource[] = [];

	for (const resource of application.resources ?? []) {
		const table = application.database?.tables?.find(x => x.ref === resource.databaseTableRef);
		const columnsToCreate = table?.columns?.filter(x => !x.created);

		if (columnsToCreate && columnsToCreate.length > 0) {
			const result = await new ResourceApi().get(resource.id ?? 0);

			const newWidgets = transformWidgetsUpdate(
				columnsToCreate,
				application,
				resource.name ?? '',
				table?.schema ?? '',
				application.database?.tables,
				resource.databaseTableRef ?? '',
				result?.widgets ?? [],
			);

			resource.widgets = newWidgets;
			resources.push({...resource});
		}
	}

	application.database?.tables
		?.filter(x => !x.created)
		.forEach(table => {
			const resourceName = formatScreenName(table.name ?? '');

			resources.push({
				ref: uuidV4().toString(),
				name: resourceName,
				type: ResourceTypeEnum.Page,
				menu: {
					use: true,
					name: resourceName,
				},
				widgets: transformWidgets(
					table.columns ?? [],
					application,
					resourceName,
					table.schema ?? '',
					application.database?.tables,
					table.ref ?? '',
				),
				useDefaultLayout: true,
				isLogin: false,
				isRoot: false,
				selected: false,
				databaseTableRef: table.ref,
				path: table.schema ? `/${dotnetSchemaName(table.schema!)}` : '',
			});
		});

	return {
		...application,
		resources,
	};
};

export const TransformToEndpoints = (application: IApplication): IApi[] => {
	let apis: IApi[] = [...(application.apis ?? [])];
	if (!apis.find(x => x.name === 'Authentication')) apis.push(getAuthEndpoint());

	application.database?.databases?.forEach(database => {
		const schemas = Array.from(new Set(application.database?.tables?.map(x => x.schema)));

		schemas.forEach(schema => {
			const apiRef = getApiRef(schema, database.name!);
			const tables = application.database?.tables?.filter(
				x => x.databaseRef === database.ref && x.schema === schema,
			);
			const currentApi = apis.find(x => x.ref == apiRef);

			if (!!currentApi) {
				let endpoints: IApiEndpoint[] = [...(currentApi?.endpoints ?? [])];

				tables?.forEach(table => {
					endpointTypes.forEach(type => {
						const currentEndpoint = endpoints.find(
							x => x.ref == getEndpointRef(type, table.schema, table.name!),
						);
						if (!!currentEndpoint) {
							endpoints = endpoints.map(endpoint => {
								if (endpoint.ref === currentEndpoint.ref) {
									endpoint = updateEndpoint(table, application, endpoint, type);
								}
								return endpoint;
							});
						} else {
							endpoints.push(createEndpoint(table, application, type as any));
						}
					});
				});

				apis = apis.map(api => {
					if (api.ref === currentApi?.ref) {
						api.endpoints = endpoints;
					}
					return api;
				});
			} else {
				const endpoints: IApiEndpoint[] = [];

				tables?.forEach(table => {
					endpointTypes.forEach(type =>
						endpoints.push(createEndpoint(table, application, type as any)),
					);
				});

				apis.push({
					ref: getApiRef(schema, database.name!),
					baseUrl: `https://localhost:57272/v1/${schema}`,
					name: `${database.name}${schema}`,
					endpoints,
					schema,
				});
			}
		});
	});

	return apis;
};

export const verifyExpandRelations = (app: IApplication): IApplication => {
	app.resources =
		app.resources?.map(resource => {
			const tables = app.database?.tables ?? [];
			const table = tables?.find(x => x.ref === resource.databaseTableRef);
			resource = {
				...resource,
				widgets:
					resource.widgets?.map(widget => {
						if (
							widget.name === ComponentSimpleEnum.Button &&
							!!widget.actions?.find(x => x.actionType === ActionTypeEnum.Search)
						) {
							widget.actions = widget.actions.map(action => {
								if (action.actionType === ActionTypeEnum.Search && table) {
									const listExpand: any[] = [];

									table.columns?.forEach(column => {
										if (column.constraint) {
											listExpand.push({
												name: column.name,
												tableName: tables.find(
													x => x.ref === column.constraint,
												)?.name,
												refName: tables
													.find(x => x.ref === column.constraint)
													?.columns?.find(
														x => x.type === DatabaseColumnEnum.String,
													)?.name,
												refId:
													tables
														.find(x => x.ref === column.constraint)
														?.columns?.find(x => x.isPrimaryKey)
														?.name ?? 'Id',
											});
										}
									});

									const newListExpande: any[] = [];
									listExpand.forEach(listItem => {
										let included = true;
										app?.databaseRules?.forEach(rule => {
											if (
												listItem.name?.toUpperCase() ==
												rule.findedName?.toUpperCase()
											) {
												included = false;
											}
										});

										if (included) newListExpande.push(listItem);
									});

									action.data.listExpand = newListExpande.map(expItem => {
										expItem.name = backFormatPropertyName(expItem.name);
										expItem.refId = backFormatPropertyName(expItem.refId);
										expItem.refName = backFormatPropertyName(expItem.refName);
										expItem.tableName = dotnetFormatScreenName(
											expItem.tableName,
										);
										return expItem;
									});
								}

								return action;
							});
						}

						return widget;
					}) ?? [],
			};

			return resource;
		}) ?? [];

	return app;
};

export const RefreshEndpointsWithInherits = (
	application: IApplication,
	hasOneToManyRelationship: boolean,
	databaseOriginTableRef: string,
	databaseDestinyTableRef: string,
	datagridName: string | undefined,
	columns?: IColumn[],
): IApi[] => {
	let apis: IApi[] = [...(application.apis ?? [])];

	const tableOrigin = application.database?.tables?.find(x => x.ref === databaseOriginTableRef);
	const tableDestiny = application.database?.tables?.find(x => x.ref === databaseDestinyTableRef);
	const database = application.database?.databases?.find(
		x => x.ref === tableDestiny?.databaseRef,
	);
	const propertyArrayName = tableOrigin?.name?.replace(`${tableDestiny?.name}`, '');
	const apiRef = getApiRef(tableDestiny?.schema, database?.name ?? '');
	const currentApi = apis.find(x => x.ref == apiRef);

	if (!!currentApi) {
		let endpoints: IApiEndpoint[] = [...(currentApi?.endpoints ?? [])];

		endpointTypesRefresh.forEach(type => {
			const currentEndpoint = endpoints.find(
				x => x.ref == getEndpointRef(type, tableDestiny?.schema, tableDestiny?.name),
			);

			if (!!currentEndpoint) {
				endpoints = endpoints.map(endpoint => {
					if (endpoint.ref === currentEndpoint.ref) {
						endpoint = updateEndpointInherits(
							tableDestiny!,
							application,
							endpoint,
							type,
							hasOneToManyRelationship,
							datagridName,
							tableOrigin?.ref,
							propertyArrayName,
							columns,
						);
					}

					return endpoint;
				});
			}
		});

		apis = apis.map(api => {
			if (api.ref === currentApi?.ref) {
				api.endpoints = endpoints;
			}

			return api;
		});
	}

	return apis;
};

const getAuthEndpoint = () => {
	return {
		ref: uuidV4().toString(),
		baseUrl: `https://localhost:57272/v1/auth`,
		name: 'Authentication',
		endpoints: [
			{
				ref: uuidV4().toString(),
				bodyType: ApiBodyType.JSON,
				method: ApiMethods.POST,
				isAuth: true,
				name: 'Auth',
				route: '/login',
				request: [
					{
						ref: uuidV4().toString(),
						name: 'Login',
						type: ApiParamTypeEnum.STRING,
					},
					{
						ref: uuidV4().toString(),
						name: 'Password',
						type: ApiParamTypeEnum.STRING,
					},
				],
				response: [
					{
						ref: uuidV4().toString(),
						name: 'Token',
						type: ApiParamTypeEnum.STRING,
					},
					{
						ref: uuidV4().toString(),
						name: 'ExpireDate',
						type: ApiParamTypeEnum.DATE,
					},
				],
			},
		],
	};
};

const transformWidgets = (
	columns: IColumn[],
	application: IApplication,
	resourceName: string,
	schema: string,
	tables: ITable[] | undefined,
	tableRef: string,
): IComponent[] => {
	const buttonWidgets: IComponent[] = [];
	let widgets: IComponent[] = [];
	const relations: IComponent[] = [];
	const allowColumns = getAllowColumn(columns, application);

	allowColumns?.forEach(column => {
		widgets = validRow(widgets);
		widgets = getWidget(column, widgets, application, tables);
	});

	getButtons(
		application,
		application.apis ?? [],
		resourceName,
		widgets,
		schema,
		tables,
		tableRef,
	).forEach(item => buttonWidgets.push(item));

	tables?.forEach(table => {
		let findedTable: ITable = {};

		table.columns?.forEach(column => {
			if (column.constraint == tableRef) {
				findedTable = table;
			}
		});

		findedTable?.columns?.forEach(column => {
			if (
				column.constraint != tableRef &&
				column.constraint &&
				column.relationType == '1ToN'
			) {
				getWidgetRelation(column, relations, application);
			}
		});
	});

	return [...buttonWidgets, ...widgets, ...relations];
};

const transformWidgetsUpdate = (
	columns: IColumn[],
	application: IApplication,
	resourceName: string,
	schema: string,
	tables: ITable[] | undefined,
	tableRef: string,
	currentWidgets: IComponent[],
): IComponent[] => {
	const buttonWidgets: IComponent[] = [];
	let widgets: IComponent[] = [];
	const relations: IComponent[] = [];
	const allowColumns = getAllowColumn(columns, application);

	const firstBtn = currentWidgets.find(
		x => x.name === ComponentSimpleEnum.Button && x.properties.text === 'Incluir',
	);

	const newWidgets = [...currentWidgets].filter(
		x =>
			!(
				x.name === ComponentSimpleEnum.Button &&
				['Incluir', 'Alterar', 'Remover', 'Consultar', 'Limpar'].includes(x.properties.text)
			),
	);

	getButtons(
		application,
		application.apis ?? [],
		resourceName,
		widgets,
		schema,
		tables,
		tableRef,
		firstBtn?.parentRef,
	).forEach(item => buttonWidgets.push(item));

	allowColumns?.forEach(column => {
		widgets = validRow(widgets);
		widgets = getWidget(column, widgets, application, tables);
	});

	tables?.forEach(table => {
		let findedTable: ITable = {};

		table.columns?.forEach(column => {
			if (column.constraint == tableRef) {
				findedTable = table;
			}
		});

		findedTable?.columns?.forEach(column => {
			if (
				column.constraint != tableRef &&
				column.constraint &&
				column.relationType == '1ToN'
			) {
				getWidgetRelation(column, relations, application);
			}
		});
	});

	newWidgets.push(...[...buttonWidgets, ...widgets, ...relations]);

	return newWidgets;
};

const getWidgetRelation = (
	item: IColumn,
	widgets: IComponent[],
	application: IApplication,
): IComponent[] => {
	widgets.push(getWrapper({margin: '0 0 12px 0'}));

	const lastRow = getLastRow(widgets);
	const componentName = mapTypeWidget(item.type!, item.constraint);
	const layoutProperties =
		application.layout?.templateConfig && application.layout?.templateConfig[componentName]
			? application.layout?.templateConfig[componentName]
			: {};
	const widthBox: any = {
		...(Components.find(x => x.name === ComponentSimpleEnum.WidthBox) ?? {}),
		ref: uuidV4().toString(),
		properties: {},
		parentRef: lastRow.ref,
	};
	const widthBox2: any = {
		...(Components.find(x => x.name === ComponentSimpleEnum.WidthBox) ?? {}),
		ref: uuidV4().toString(),
		properties: {
			width: 'auto',
			margin: '20px 0 0 0',
		},
		parentRef: lastRow.ref,
	};
	const input: any = {
		...(Components.find(x => x.name === componentName) ?? {}),
		ref: uuidV4().toString(),
		properties: {
			...layoutProperties,
			...getPropsByComponent(componentName, item),
			name: backFormatPropertyName(item.name ?? ''),
			internalName: item.internalName ?? '',
			[item.type === DatabaseColumnEnum.Boolean ? 'text' : 'label']: upperFirstWord(
				formatColumnLabelName(item.name ?? ''),
			),
		},
		parentRef: widthBox.ref,
		columnRef: item.ref,
	};
	const grid: any = {
		...(Components.find(x => x.name === ComponentComplexEnum.DataGrid) ?? {}),
		ref: uuidV4().toString(),
		parentRef: undefined,
		type: 2,
		name: ComponentComplexEnum.DataGrid,
		properties: {
			//bodyBackground: '#d6d6d9',
			//bodyTextColor: '#000',
			//headerTextColor: '#000',
			//template: '1fr',
			data: [],
			dataActions: [],
			header: ['Nome'],
			name: `grid${input.properties.name}`,
			properties: ['Nome'],
			propertyEditableMap: {Nome: {selected: false}},
			propertyExcludedMap: {Nome: true},
			propertyMap: {Nome: input.properties.name},
		},
	};
	const action = {
		actionName: ComponentComplexEnum.DataGrid,
		actionType: ActionTypeEnum.DataGrid,
		items: [
			{
				propertyName: input.properties.name,
				componentRef: input.ref,
			},
		],
		ref: uuidV4().toString(),
		targetRef: grid.ref,
	};
	const button: any = {
		...Components.find(x => x.name === ComponentSimpleEnum.Button),
		ref: uuidV4().toString(),
		parentRef: widthBox2.ref,
		properties: {
			fill: 'auto',
			themeStyle: 'success',
			onClick: () => console.log('sem ação'),
			icon: faAdd,
		},
		actions: [action],
	};

	widgets.push(widthBox);
	widgets.push(widthBox2);
	widgets.push(input);
	widgets.push(button);
	widgets.push(grid);

	return widgets;
};

const validRow = (widgets: IComponent[]): IComponent[] => {
	if (widgets.length === 0) {
		widgets.push(getWrapper({margin: '0 0 12px 0'}));
		return widgets;
	}

	const lastRow = getLastRow(widgets);
	let count = 0;
	widgets.forEach(item => {
		if (item.parentRef === lastRow.ref) count++;
	});
	if (count === 3) widgets.push(getWrapper({margin: '0 0 12px 0'}));

	return widgets;
};

const getWrapper = (customProps = {}): any => {
	return {
		...(Components.find(x => x.name === ComponentSimpleEnum.Wrapper) ?? {}),
		ref: uuidV4().toString(),
		properties: customProps,
	};
};

const getWidget = (
	item: IColumn,
	widgets: IComponent[],
	application: IApplication,
	tables: ITable[] | undefined,
): IComponent[] => {
	const lastRow = getLastRow(widgets);
	const widthBox: any = {
		...(Components.find(x => x.name === ComponentSimpleEnum.WidthBox) ?? {}),
		ref: uuidV4().toString(),
		properties: {},
		parentRef: lastRow.ref,
	};
	const validation: IValidation[] = [];

	if (!item.nullable) {
		validation.push({
			ref: uuidV4().toString(),
			label: 'Este campo é obrigatório',
			message: 'Este campo é obrigatório',
			type: ValidationTypeEnum.Required,
		});
	}

	if (item?.mask !== undefined) {
		if (
			item.mask == DatabaseMaskEnum.Cpf ||
			item.mask == DatabaseMaskEnum.Cnpj ||
			item.mask == DatabaseMaskEnum.CpfCnpj
		) {
			validation.push({
				ref: uuidV4().toString(),
				label: 'CPF/CNPJ válido',
				message: 'CPF/CNPJ inválido',
				type: ValidationTypeEnum.CnpjCpf,
			});
		}

		if (item.mask == DatabaseMaskEnum.Email) {
			validation.push({
				ref: uuidV4().toString(),
				label: 'E-mail válido',
				message: 'E-mail inválido',
				type: ValidationTypeEnum.Email,
			});
		}

		if (item.mask == DatabaseMaskEnum.URL) {
			validation.push({
				ref: uuidV4().toString(),
				label: 'URL válida',
				message: 'URL inválida',
				type: ValidationTypeEnum.Url,
			});
		}
	}

	const componentName = mapTypeWidget(item.type!, item.constraint);
	const layoutProperties =
		application.layout?.templateConfig && application.layout?.templateConfig[componentName]
			? application.layout?.templateConfig[componentName]
			: {};
	//debugger;
	const input: any = {
		...(Components.find(x => x.name === componentName) ?? {}),
		ref: uuidV4().toString(),
		properties: {
			...layoutProperties,
			...getPropsByComponent(componentName, item),
			name: backFormatPropertyName(item.name ?? ''),
			internalName: item.internalName ?? '',
			[item.type === DatabaseColumnEnum.Boolean ? 'text' : 'label']: item.suggestName
				? decodeURIComponent(item.suggestName)
				: upperFirstWord(formatColumnLabelName(item.name ?? '')),
			search: true,
		},
		parentRef: widthBox.ref,
		columnRef: item.ref,
		valition: validation,
	};

	if (
		componentName === ComponentSimpleEnum.Autocomplete &&
		item.constraint &&
		item.relationType == '1To1'
	) {
		const mTable = tables?.find(x => x.ref === item.constraint);

		input.properties.finderData = {
			tableRef: mTable?.ref,
			propData: {
				id: mTable?.columns?.find(x => x.isPrimaryKey && !x.constraint)?.ref,
				value: mTable?.columns?.find(x => x.type === DatabaseColumnEnum.String)?.ref,
			},
		};
		input.properties.optionType = 2;
		input.properties.search = true;
	}

	widgets.push(widthBox);
	widgets.push(input);

	return widgets;
};

const mapTypeWidget = (type: DatabaseColumnEnum, constraint?: string): ComponentSimpleEnum => {
	if (constraint) return ComponentSimpleEnum.Autocomplete;

	switch (type) {
		case DatabaseColumnEnum.Boolean:
			return ComponentSimpleEnum.Switch;
		case DatabaseColumnEnum.Date:
			return ComponentSimpleEnum.InputDate;
		default:
			return ComponentSimpleEnum.Input;
	}
};

const getLastRow = (widgets: IComponent[]) =>
	widgets
		.filter(
			x => x.type === ComponentTypeEnum.Container && x.name === ComponentSimpleEnum.Wrapper,
		)
		.reverse()[0];

const getPropsByComponent = (component: ComponentSimpleEnum, item: IColumn) => {
	let mMask: any = '';
	if (item.mask) mMask = item.mask;
	else if (
		item.type === DatabaseColumnEnum.Int16 ||
		item.type === DatabaseColumnEnum.Int32 ||
		item.type === DatabaseColumnEnum.Int64 ||
		item.type === DatabaseColumnEnum.Byte
	)
		mMask = DatabaseMaskEnum.Numero;
	else if (item.type === DatabaseColumnEnum.Decimal) mMask = DatabaseMaskEnum.Monetario;
	else if ((item.name?.toUpperCase().indexOf('CEP') ?? -1) >= 0) mMask = DatabaseMaskEnum.Cep;
	else if ((item.name?.toUpperCase().indexOf('TEL') ?? -1) >= 0)
		mMask = DatabaseMaskEnum.Telefone;
	else if ((item.name?.toUpperCase().indexOf('CPF') ?? -1) >= 0) mMask = DatabaseMaskEnum.Cpf;
	else if ((item.name?.toUpperCase().indexOf('CNPJ') ?? -1) >= 0) mMask = DatabaseMaskEnum.Cnpj;
	else if ((item.name?.toUpperCase().indexOf('CEL') ?? -1) >= 0)
		mMask = DatabaseMaskEnum.Telefone;
	else if (
		(item.name?.toUpperCase().indexOf('HR') ?? -1) >= 0 ||
		(item.name?.toUpperCase().indexOf('HORA') ?? -1) >= 0
	)
		mMask = DatabaseMaskEnum.Hora;

	switch (component) {
		case ComponentSimpleEnum.Input:
			return {
				mask: mMask,
				maxLength: item.maxLength ? item.maxLength : undefined,
			};
		default:
			return {};
	}
};

export const formatScreenName = (name: string): string => {
	const removePrefix = ['TB_', 'TABLE_', 'i9z_']; //TODO: este prefixo precisa ir para parametrização
	let nameFormat = removeDiacritics(name).toUpperCase();

	removePrefix.forEach(item => {
		nameFormat = nameFormat.replace(item, '');
	});

	const nameSplited = nameFormat.split('_').map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join('');
};

const getButtons = (
	application: IApplication,
	api: IApi[],
	resourceName: string,
	widgets: IComponent[],
	schema: string,
	tables: ITable[] | undefined,
	tableRef: string,
	parentRef?: string,
): IComponent[] => {
	const components: IComponent[] = [];
	const wrapper = getWrapper({
		margin: '0 0 16px 0',
	});

	const addBtn: any = constructButton(
		application,
		parentRef ?? wrapper.ref,
		'Incluir',
		faAdd,
		getButtonAction(api, 'Incluir', resourceName, widgets, schema),
	);
	const updateBtn: any = constructButton(
		application,
		parentRef ?? wrapper.ref,
		'Alterar',
		faEdit,
		getButtonAction(api, 'Alterar', resourceName, widgets, schema),
	);
	const removeBtn: any = constructButton(
		application,
		parentRef ?? wrapper.ref,
		'Excluir',
		faTrash,
		getButtonAction(api, 'Remover', resourceName, widgets, schema),
	);
	const searchBtn: any = constructButton(
		application,
		parentRef ?? wrapper.ref,
		'Consultar',
		faSearch,
		getSearchAction(api, resourceName, widgets, schema, tables, tableRef),
	);
	const clearBtn: any = constructButton(
		application,
		parentRef ?? wrapper.ref,
		'Limpar',
		faClose,
		getClearAction(),
	);

	components.push(wrapper);
	components.push(clearBtn);
	components.push(addBtn);
	components.push(updateBtn);
	components.push(searchBtn);
	components.push(removeBtn);

	return components;
};

const constructButton = (
	application: IApplication,
	wrapperRef: string,
	text: string,
	icon: IconDefinition,
	action?: any,
) => {
	const layoutProperties =
		application.layout?.templateConfig && application.layout?.templateConfig['Button']
			? application.layout?.templateConfig['Button']
			: {};
	return {
		...Components.find(x => x.name === ComponentSimpleEnum.Button),
		ref: uuidV4().toString(),
		parentRef: wrapperRef,
		properties: {
			...layoutProperties,
			fill: 'auto',
			text: text,
			onClick: () => console.log('sem ação'),
			leftIcon: icon,
		},
		actions: action,
	};
};

const getApiType = (type: DatabaseColumnEnum): string => {
	switch (type) {
		case DatabaseColumnEnum.Boolean:
			return ApiParamTypeEnum.BOOLEAN;
		case DatabaseColumnEnum.Date:
		case DatabaseColumnEnum.DateOnly:
		case DatabaseColumnEnum.DateTime:
			return ApiParamTypeEnum.DATE;
		case DatabaseColumnEnum.Byte:
		case DatabaseColumnEnum.Int16:
		case DatabaseColumnEnum.Int32:
		case DatabaseColumnEnum.Int64:
		case DatabaseColumnEnum.Decimal:
			return ApiParamTypeEnum.NUMBER;
		default:
			return ApiParamTypeEnum.STRING;
	}
};

const getApiRequest = (columns: IColumn[]): IApiMap[] => {
	const request: IApiMap[] = columns?.map(item => {
		item.type;
		return {
			ref: uuidV4().toString(),
			//name: backFormatPropertyName(item.name ?? ''),
			name: item.internalName ?? '',
			type: getApiType(item.type!),
		};
	});

	return request;
};

const createEndpoint = (table: ITable, app: IApplication, type: string): IApiEndpoint => {
	const name = formatScreenName(table.name ?? '');
	const allowColumns = getAllowColumn(table.columns ?? [], app, type);
	const method = mapRequestMethod(type);

	const endpoints: IApiEndpoint = {
		ref: getEndpointRef(type, table.schema, table.name!),
		name: `${type}${name}`,
		route: `/${name}`,
		method: method,
		bodyType: ApiBodyType.JSON,
		isAuth: true,
		request: method === ApiMethods.GET ? [] : getApiRequest(allowColumns),
		databaseTableRef: table.ref,
	};

	return endpoints;
};

const updateEndpoint = (
	table: ITable,
	app: IApplication,
	endpoint: IApiEndpoint,
	type: string,
): IApiEndpoint => {
	const allowColumns = getAllowColumn(table.columns ?? [], app, type);
	const method = mapRequestMethod(type);
	endpoint.isAuth = true;
	endpoint.request = method === ApiMethods.GET ? [] : getApiRequest(allowColumns);

	return endpoint;
};

const updateEndpointInherits = (
	table: ITable,
	app: IApplication,
	endpoint: IApiEndpoint,
	type: string,
	hasOneToManyRelationship: boolean,
	datagridName?: string | undefined,
	databaseOriginTableRef?: string,
	propertyArrayName?: string,
	columnsInherits?: IColumn[],
): IApiEndpoint => {
	const allowColumns = getAllowColumn(table.columns ?? [], app, type);
	const allowColumnsInherits = getAllowColumn(columnsInherits ?? [], app, type);
	const method = mapRequestMethod(type);

	if (hasOneToManyRelationship) {
		endpoint.request = method === ApiMethods.GET ? [] : getApiRequest(allowColumns);

		endpoint.request.push({
			ref: uuidV4().toString(),
			name: backFormatPropertyName(formatScreenName(propertyArrayName!) ?? ''),
			type: 'array',
			subtype: databaseOriginTableRef,
			componentRef: datagridName,
		});
	} else {
		endpoint.request =
			method === ApiMethods.GET
				? []
				: getApiRequest([...allowColumns, ...allowColumnsInherits]);
	}

	return endpoint;
};

const mapRequestMethod = (type: string): ApiMethods => {
	switch (type) {
		case 'Incluir':
			return ApiMethods.POST;
		case 'Alterar':
			return ApiMethods.PUT;
		case 'Remover':
			return ApiMethods.DELETE;
		default:
			return ApiMethods.GET;
	}
};

const getAllowColumn = (columns: IColumn[], app: IApplication, type?: string): IColumn[] => {
	let allowColumns: IColumn[] =
		type === 'Incluir'
			? [...columns?.filter(x => !x.autoIncremente)]
			: type === 'Alterar'
			? [...columns]
			: type === 'Remover'
			? [...columns?.filter(x => x.autoIncremente)]
			: [...columns?.filter(x => !x.autoIncremente)];

	app?.databaseRules?.forEach(rule => {
		columns?.forEach(column => {
			if (column.name?.toUpperCase() == rule.findedName!.toUpperCase()) {
				allowColumns = allowColumns.filter(x => x.ref !== column.ref);
			}

			if (column.name?.toUpperCase().lastIndexOf(rule.findedName!.toUpperCase()) === 0) {
				allowColumns = allowColumns.filter(x => x.ref !== column.ref);
			}
		});
	});

	return allowColumns;
};

const getButtonAction = (
	apis: IApi[],
	type: 'Incluir' | 'Alterar' | 'Remover',
	resourceName: string,
	widgets: IComponent[],
	schema: string,
): IAction[] => {
	const api = apis.find(x => x.schema === schema);
	if (!api) return [];
	const endpoint = api.endpoints?.find(x => x.name == `${type}${resourceName}`);
	if (!endpoint) return [];

	const mapRequest: IActionApiMap[] = widgets
		.filter(x => DataInputComponent.includes(x.name))
		.map(item => {
			return {
				targetApiRef:
					endpoint.request?.find(x => x.name === item.properties.internalName)?.ref ?? '',
				targetComponentRef: item.ref ?? '',
			};
		});

	return [
		{
			ref: uuidV4().toString(),
			actionName: 'Validar formulário',
			actionType: ActionTypeEnum.Validation,
		},
		{
			ref: uuidV4().toString(),
			actionName: 'Configurar API',
			actionType: ActionTypeEnum.Api,
			showLoading: true,
			api: {
				targetApi: api.ref,
				targetEndpoint: endpoint.ref,
				mapRequest: mapRequest,
			},
			modal: {
				enable: true,
				description: `Deseja realmente ${
					type === 'Incluir' ? 'incluir' : type === 'Alterar' ? 'alterar' : 'excluir'
				}?`,
				title: 'Atenção',
			},
		},
	];
};

const getSearchAction = (
	apis: IApi[],
	resourceName: string,
	widgets: IComponent[],
	schema: string,
	tables: ITable[] | undefined,
	tableRef: string,
): IAction[] => {
	const api = apis.find(x => x.schema === schema);
	const endpoint = api?.endpoints?.find(x => x.name == `OData${resourceName}`);
	const data =
		widgets?.filter(item => DataInputComponent.includes(item.name))?.map(item => item.ref) ??
		[];
	const listExpand: any[] = [];

	tables
		?.find(x => x.ref === tableRef)
		?.columns?.forEach(column => {
			if (column.constraint) {
				listExpand.push({
					name: column.name,
					tableName: tables.find(x => x.ref === column.constraint)?.name,
					refName: tables
						.find(x => x.ref === column.constraint)
						?.columns?.find(x => x.type === DatabaseColumnEnum.String)?.name,
					refId:
						tables
							.find(x => x.ref === column.constraint)
							?.columns?.find(x => x.isPrimaryKey)?.name ?? 'Id',
				});
			}
		});

	return [
		{
			ref: uuidV4().toString(),
			actionName: 'Configurar mecanismo de pesquisa',
			actionType: ActionTypeEnum.Search,
			showLoading: true,
			api: {
				targetApi: api?.ref,
				targetEndpoint: endpoint?.ref,
			},
			data: {
				listWidgetFilter: data,
				listExpand: listExpand,
			},
		},
	];
};

const getClearAction = (): IAction[] => {
	return [
		{
			ref: uuidV4().toString(),
			actionName: 'Limpar',
			actionType: ActionTypeEnum.Clear,
			showLoading: false,
		},
	];
};

export const getConstraintName = (name: string): string => {
	name =
		name.substring(0, 2).toUpperCase() == 'ID' && name.substring(0, 5).toUpperCase() != 'IDENT'
			? name.substring(2)
			: name;
	name = name.replace('Cod', '');
	name = name.replace('cod', '');
	name = upperFirstWord(name);

	return name;
};
