import React from 'react';
import * as S from './styles';
import {IApplication} from 'src/@types/app';

type Props = {
	isOpenMenu: boolean;
	setIsOpenMenu: (val: boolean) => void;
	app: IApplication;
};

const TopBar = ({isOpenMenu, setIsOpenMenu, app}: Props) => {
	return (
		<S.Container>
			<S.Logo isOpen={isOpenMenu}>
				{app.layout?.logo ? (
					<img
						style={{
							display: 'block',
							width: '43px',
							height: '43px',
							borderRadius: '43px',
							marginLeft: '2px',
						}}
						id="base64image"
						src={app.layout?.logo}
					/>
				) : (
					<></>
				)}
			</S.Logo>
			<S.MenuAction isOpen={isOpenMenu}>
				<S.MenuActionBtn
					onClick={() => setIsOpenMenu(!isOpenMenu)}
					backTopColor={app?.layout?.templateConfig?.Layout?.backTop}
					iconColor={app?.layout?.templateConfig?.Layout?.icon}>
					<i className="fa fa-bars"></i>
				</S.MenuActionBtn>
			</S.MenuAction>
			<S.IconsContent>
				<S.MenuActionBtn
					backTopColor={app?.layout?.templateConfig?.Layout?.backTop}
					iconColor={app?.layout?.templateConfig?.Layout?.icon}>
					<i className="fa fa-bars"></i>
				</S.MenuActionBtn>
				<S.MenuActionBtn
					backTopColor={app?.layout?.templateConfig?.Layout?.backTop}
					iconColor={app?.layout?.templateConfig?.Layout?.icon}>
					<i className="fa fa-bell"></i>
				</S.MenuActionBtn>
				<S.MenuActionBtn
					backTopColor={app?.layout?.templateConfig?.Layout?.backTop}
					iconColor={app?.layout?.templateConfig?.Layout?.icon}>
					<i className="fa fa-user"></i>
				</S.MenuActionBtn>
				<S.MenuActionBtn
					backTopColor={app?.layout?.templateConfig?.Layout?.backTop}
					iconColor={app?.layout?.templateConfig?.Layout?.icon}>
					<i className="fa fa-ellipsis-vertical"></i>
				</S.MenuActionBtn>
			</S.IconsContent>
		</S.Container>
	);
};

export default TopBar;
