/* eslint-disable @typescript-eslint/no-explicit-any */
import {IApplication, IComponent, IOption, ISubGenerate} from 'src/@types/app';
import {ComponentComplexEnum, ComponentSimpleEnum, ValidationTypeEnum} from 'src/@types/enums';
import {ITabConfig} from 'src/shared/components/Complex/Tab';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';
import {getListIcons} from 'src/helpers/select-data/iconList';
import {EXCLUDE_PROPERTIES, GET_REF, ICON_PROPS} from '../engine-enum';
import {addDataGridAction} from './addAction';
import {getRepositoryClass} from '../repository-generator';
import {formatScreenName} from 'src/helpers/methods/database-to-screen';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';

export const addProperties = (
	widget: IComponent,
	iconImports: string[],
	widgets: IComponent[],
	allSubcomponents: ISubGenerate[],
	importSubcomponents: string[],
	isSubComponent: boolean,
	fileInstances: string[],
	apiImports: string[],
	application: IApplication,
	widgetImports: string[],
	anotherImports: string[],
): string => {
	if (!widget || !widget.properties) return '';

	const props = Object.keys(widget.properties);
	const objName = widget.properties['name'];
	const getSetName: string[] = [];

	let propertiesString = ``;

	if (widget.options) {
		const mask = widget?.properties?.mask ?? '';
		const optionsKeys = Object.keys(widget.options);

		optionsKeys.forEach(opt => {
			if (widget.options[opt] === 'isOn')
				propertiesString += `\n isOn={formData?.${objName} ?? false}`;
			else if (
				widget.options[opt] === 'valueProp' &&
				widget.name == ComponentSimpleEnum.Autocomplete
			)
				propertiesString += '';
			else if (widget.options[opt] === 'valueProp') {
				//if (mask === 'Monetário' || mask === 'Numero') {
				if (mask === 'Numero') {
					propertiesString += `\n ${opt}={formData?.${objName}?.toString()}`;
				} else {
					propertiesString += `\n ${opt}={formData?.${objName}}`;
				}

				getSetName.push(opt);
			}
			if (widget.options[opt] === 'changeProp') {
				if (opt === 'handleToggle') {
					propertiesString += `\n ${opt}={() => setFormData({...formData, ${objName}: !formData?.${objName}})}`;
				} else if (widget.name === ComponentSimpleEnum.Select) {
					propertiesString += `\n ${opt}={val => setFormData({...formData, ${objName}: val.value})}`;
				} else {
					//if (mask === 'Monetário') {
					//propertiesString += `\n ${opt}={val => setFormData({...formData, ${objName}: parseFloat(val)})}`;
					//} else if (mask === 'Numero') {
					if (mask === 'Numero') {
						propertiesString += `\n ${opt}={val => setFormData({...formData, ${objName}: parseInt(val)})}`;
					} else {
						propertiesString += `\n ${opt}={val => setFormData({...formData, ${objName}: val})}`;
					}
				}

				getSetName.push(opt);
			}
		});
	}

	if (widget.name !== ComponentSimpleEnum.Input) {
		if (props.includes('mask')) {
			const indexProp = props.indexOf('mask');
			props.splice(indexProp, 1);
		}
	}

	props.forEach(prop => {
		if (!getSetName.includes(prop) && !EXCLUDE_PROPERTIES.includes(prop)) {
			const propValue = widget.properties[prop];

			if (ICON_PROPS.includes(prop))
				propertiesString = setIcon(prop, propertiesString, propValue, iconImports);
			else if (prop === 'finderData') {
				const finderValues: any = propValue;
				const finderTable = application.database?.tables?.find(
					x => x.ref === finderValues.tableRef,
				);
				const currApi = application.apis?.find(x => x.schema === finderTable?.schema);
				const nameRepo = getRepositoryClass(currApi?.name ?? '');
				const instanceName = `${lowerFirstWord(nameRepo)}`;
				const mResourceName = formatScreenName(finderTable?.name ?? '');
				const valueColumn = backFormatPropertyName(
					finderTable?.columns?.find(x => x.ref === finderValues?.propData?.value)
						?.name ?? '',
				);
				const finderAddColumns = finderValues?.propData?.aditionalLabels?.map(
					(aditionalLabel: any) => {
						const finderAddColumn =
							finderTable?.columns?.find(x => x.ref === aditionalLabel?.value)
								?.name ?? '';

						return backFormatPropertyName(finderAddColumn);
					},
				);
				const finderCustomColumns = finderValues?.propData?.customProperties?.map(
					(customProperty: any) => {
						const finderAddColumn =
							finderTable?.columns?.find(x => x.ref === customProperty?.value)
								?.name ?? '';

						return backFormatPropertyName(finderAddColumn);
					},
				);

				let filterString = '';
				let expandString = '';
				let valueChangeCode = '';

				if (
					widget.properties.dependencyList &&
					widget.properties.dependencyList.length > 0
				) {
					filterString = '$filter=';
					expandString = '$expand=';

					widget.properties.dependencyList.forEach((item: IOption, widgetIdx: number) => {
						const column = application.database?.tables
							?.find(table =>
								table.columns?.find(column => column.ref === item.value),
							)
							?.columns?.find(column => column.ref === item.value);

						const table = application.database?.tables?.find(
							x => x.ref === column?.constraint,
						);

						if (widgetIdx > 0) expandString += ',';
						const expandName = formatScreenName(table?.name ?? '');
						expandString += expandName;

						const findPropName = backFormatPropertyName(item.label);
						filterString += `${expandName}/Id eq \${formData?.${findPropName}}`;
						valueChangeCode = `\n valueChanged={formData?.${findPropName}}`;
					});

					filterString += '&';
					expandString += '&';
				}

				let finderComplementAdd1 = finderAddColumns ? `,${finderAddColumns.join(',')}` : '';
				const finderComplementAddString = finderAddColumns
					? `,${finderAddColumns.map((x: any) => `'${x}'`).join(',')}`
					: '';
				let finderComplementAdd2 = finderCustomColumns
					? `,${finderCustomColumns.join(',')}`
					: '';
				const finderCustomString = finderCustomColumns
					? `${finderCustomColumns.map((x: any) => `'${x}'`).join(',')}`
					: '';

				if (finderComplementAdd1.length == 1) finderComplementAdd1 = '';
				if (finderComplementAdd2.length == 1) finderComplementAdd2 = '';

				const finderFunction = `() => _${instanceName}.OData${mResourceName}(\`?${expandString}${filterString}$select=Id,${valueColumn}${finderComplementAdd1}${finderComplementAdd2}\`)`;

				const instance = `const _${instanceName} = new ${instanceName}();`;
				const repoName = instanceName.replace('Repository', '');
				const apiImport = `import {${instanceName}} from 'src/shared/repositories/${repoName}-repository';`;

				propertiesString += `\n finderFunction={${finderFunction}} ${valueChangeCode} value={formData?.${widget.properties?.name}} \nlabelColumns={['${valueColumn}'${finderComplementAddString}]} \ncustomColumns={[${finderCustomString}]}`;
				if (!fileInstances.includes(instance)) fileInstances.push(instance);
				if (!apiImports.includes(apiImport)) apiImports.push(apiImport);
			} else if (
				widget.name === ComponentComplexEnum.DataGrid &&
				(prop === 'columns' || prop === 'rowsData')
			)
				propertiesString = setGrid(
					prop,
					propertiesString,
					propValue,
					objName,
					widget,
					widgets,
					widgetImports,
					anotherImports,
				);
			else if (widget.name === ComponentComplexEnum.UploadFile && prop === 'importFile')
				propertiesString = setUploadFile(prop, propertiesString, objName);
			else if (widget.name === ComponentComplexEnum.UploadFile && prop === 'qtyFile')
				propertiesString += `\n qtyFile={${propValue}}`;
			else if (widget.name === ComponentComplexEnum.LabelValue && prop === 'text')
				propertiesString += setLabelValue(propValue, isSubComponent);
			else if (widget.name === ComponentComplexEnum.Chart && prop === 'dataOptions')
				propertiesString += setChartDataOptions(prop, propertiesString, propValue);
			else if (widget.name === ComponentComplexEnum.Chart && prop === 'data')
				propertiesString += setChartData(prop, propertiesString, widget.properties);
			else if (widget.name === ComponentComplexEnum.Tab && prop === 'tabs')
				propertiesString += setTab(prop, propertiesString, widget.properties);
			else if (widget.name === ComponentComplexEnum.Timeline)
				propertiesString = setTimeline(
					prop,
					propertiesString,
					propValue,
					iconImports,
					widget.properties,
					allSubcomponents,
					importSubcomponents,
				);
			else if (prop === 'noShow') propertiesString += `\n hide={${propValue}}`;
			else if (prop === 'options' && propValue)
				propertiesString = setSelectOptions(prop, propertiesString, propValue);
			else if (prop === 'disabled') propertiesString += `\n disabled={${propValue}}`;
			else if (prop === 'hide') propertiesString += `\n hide={${propValue}}`;
			else if (prop === 'search') propertiesString += `\n search={${propValue}}`;
			else if (prop === 'mask' && !propValue) propertiesString += '';
			else if (prop === 'maxLength') propertiesString += `\n maxLength={${propValue}}`;
			else if (propValue instanceof Array)
				propertiesString = setArray(prop, propertiesString, propValue);
			else if (Number.isInteger(propValue) || typeof propValue !== 'string')
				propertiesString += `\n ${prop}={${propValue}}`;
			else propertiesString += `\n ${prop}="${propValue}"`;
		}
	});

	if (widget.valition && widget.valition.length > 0) {
		propertiesString += `\n errorMessage={formError?.${widget.properties.name}}`;
		if (widget.valition.find(item => item.type === ValidationTypeEnum.Required))
			propertiesString += `\n required={true}`;
	}

	return propertiesString;
};

const getIconName = (icon: any, iconImports: string[]): string => {
	let mIconName = '';
	if (icon.hasOwnProperty('iconName')) mIconName = icon.iconName;
	else mIconName = icon.icon.iconName;

	const iconName = getListIcons().find(item => item.icon?.iconName === mIconName)?.value ?? '';
	iconImports.push(iconName);
	return iconName;
};

const setIcon = (
	prop: string,
	propertiesString: string,
	propValue: any,
	iconImports: string[],
): string => {
	propertiesString += `\n ${prop}={${getIconName(propValue, iconImports)}}`;
	return propertiesString;
};

const setGrid = (
	prop: string,
	propertiesString: string,
	propValue: any,
	objName: any,
	widget: IComponent,
	widgets: IComponent[],
	widgetImports: string[],
	anotherImports: string[],
): string => {
	if (prop === 'rowsData')
		propertiesString += `\n ${prop}={formData?.${objName.replace('grid', '')} ?? []}`;
	else if (prop === 'columns') {
		let stringData = '[\n';
		const actions = widget.properties?.dataColumns?.filter((x: any) => x.columnType === 2);
		widgetImports.push('RowOptions');
		widgetImports.push('Text');
		anotherImports.push(`import {MenuItem} from '@mui/material';`);
		anotherImports.push(`import FeatherIcon from 'feather-icons-react';`);

		if (actions && actions.length > 0) {
			let menuActionItens = '';
			actions.forEach((item: any) => {
				menuActionItens += `
									<MenuItem
										onClick={${addDataGridAction(objName, widget, widgets, item)}}
										sx={{'& svg': {mr: 2}}}>
										<FeatherIcon icon="${item.icon?.value}" size={16} />
										<Text text="${item.name}" />
									</MenuItem>
				`;
			});

			stringData += `
				{
					flex: 0.1,
					minWidth: 70,
					type: 'actions',
					filterable: false,
					hideable: false,
					headerAlign: 'left',
					align: 'left',
					sortable: false,
					field: 'actions',
					headerName: '',
					renderCell: ({row}: any) => (
						<RowOptions
							childrens={
								<>
									${menuActionItens}
								</>
							}
						/>
					),
				},`;
		}

		propValue
			?.filter((x: any) => x.field)
			?.forEach((item: any) => {
				stringData += `{
				flex: ${item.flex},
				type: 'string',
				filterable: true,
				headerAlign: 'left',
				align: 'left',
				sortable: true,
				field: '${item.field}',
				headerName: '${item.headerName}',
				renderCell: ({row}: any) => row.${item.field},
			},`;
			});
		stringData += ']';

		propertiesString += `\n ${prop}={${stringData}}`;
	}

	return propertiesString;
};

const setArray = (prop: string, propertiesString: string, propValue: any): string => {
	let arrayString = '[';
	(propValue as string[]).forEach(item => {
		arrayString += `'${item}',`;
	});
	if (arrayString.length > 2) arrayString = arrayString.substring(0, arrayString.length - 1);
	arrayString += ']';
	propertiesString += `\n ${prop}={${arrayString}}`;
	return propertiesString;
};

const setUploadFile = (prop: string, propertiesString: string, objName: any): string => {
	propertiesString += `\n files={${objName}}`;
	propertiesString += `\n setFiles={set${upperFirstWord(objName)}}`;

	return propertiesString;
};

const setSelectOptions = (prop: string, propertiesString: string, propValue: any): string => {
	let mapValues = '';
	propValue.forEach((x: any) => {
		mapValues += `{value: '${x.value}', label: '${x.label}'},`;
	});
	propertiesString = `${propertiesString} ${prop}={[${mapValues}]}`;

	return propertiesString;
};

const setChartDataOptions = (prop: string, propertiesString: string, propValue: any): string => {
	let code = '';

	propValue.forEach((x: any) => {
		code += '{';
		const keys = Object.keys(x);
		keys.forEach(key => {
			if (x[key] != undefined) code += `${key}: '${x[key]}',`;
		});
		code += '},';
	});

	propertiesString = `${prop}={[${code}]}`;
	return propertiesString;
};

const setChartData = (prop: string, propertiesString: string, props: any): string => {
	const dataOrigin = `${props?.dataRef?.label}.${props?.propRef}`.split('.').join('?.');

	propertiesString = `${prop}={${dataOrigin} ?? []}`;
	return propertiesString;
};

const setTab = (prop: string, propertiesString: string, props: any): string => {
	let code = '';

	props.tabs.forEach((tab: ITabConfig) => {
		code += `{
			name: '${tab.name}',
			children: (<>${GET_REF(tab.ref ?? '')}</>)
		},`;
	});

	propertiesString = `${prop}={[${code}]}`;
	return propertiesString;
};

const setTimeline = (
	prop: string,
	propertiesString: string,
	propValue: any,
	iconImports: string[],
	props: any,
	allSubcomponents: ISubGenerate[],
	importSubcomponents: string[],
): string => {
	if (prop === 'firstIcon' || prop === 'lastIcon') {
		const iconName = propValue.icon.value;
		iconImports.push(iconName);
		propertiesString += `${prop}={${iconName}}`;
	} else if (prop === 'dataIcon') {
		let code = '[';
		propValue?.forEach((item: any) => {
			iconImports.push(item.icon.value);
			code += `
				{
					color: '${item.color}',
					icon: ${item.icon.value},
					status: '${item.status}'
				},
			`;
		});
		code += ']';
		propertiesString += `${prop}={${code}}`;
	} else if (prop === 'orientation') propertiesString += `${prop}='${propValue}'`;
	else if (prop === 'propStatus') propertiesString += `${prop}='${propValue}'`;
	else if (prop === 'useHeader') propertiesString += `${prop}={${propValue}}`;
	else if (prop === 'subProp' && propValue && propValue != '')
		propertiesString += `${prop}='${propValue}'`;
	else if (prop === 'iconWidth' && propValue && propValue != '')
		propertiesString += `${prop}='${propValue}'`;
	else if (prop === 'data') {
		const data = `${props?.dataRef?.label}.${props?.propRef}`.split('.').join('?.');
		propertiesString += `${prop}={${data}}`;
	} else if (prop === 'leftContent') {
		const subName = allSubcomponents?.find(x => x.ref == props?.subComponentRef)?.name;
		propertiesString += `renderLeft={(item: any) => (
			<${subName} data={item} />
		)}`;
		importSubcomponents.push(subName ?? '');
	}

	return propertiesString;
};

const setLabelValue = (propValue: any, isSubComponent: boolean) => {
	let code = '';
	if (isSubComponent) code = `\n text={data?.${propValue?.split('.').join('?.')}}`;
	else code = `\n text={${propValue?.split('.').join('?.')}}`;

	return code;
};
