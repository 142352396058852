import {IColumn} from 'src/@types/app';
import {formatScreenName} from 'src/helpers/methods/database-to-screen';
import {removeDiacritics} from 'src/helpers/methods/removeDiacritics';
import {upperFirstWord} from 'src/helpers/methods/text-methods';

export const backFormatPropertyName = (name: string, isTableKey = false): string => {
	if (isTableKey) return 'Id';
	const nameFormat = removeDiacritics(name).toUpperCase();
	const nameSplited = nameFormat.split('_').map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join('');
};

export const propertyNameForBackend = (tableName: string, name: string): string => {
	const fromTo: Map<string, string> = new Map<string, string>();
	fromTo.set('BASE', 'Base');
	fromTo.set('BR', 'Bairro');
	fromTo.set('CEL', 'Celular');
	fromTo.set('CEP', 'Cep');
	fromTo.set('CFOP', 'Cfop');
	fromTo.set('CHAVE', 'Chave');
	fromTo.set('CID', 'Localidade');
	fromTo.set('CNPJ', 'Cnpj');
	fromTo.set('COD', 'Codigo');
	fromTo.set('COMP', 'Complemento');
	fromTo.set('CPF', 'Cpf');
	fromTo.set('CSS', 'Css');
	fromTo.set('CUBAGEM', 'Cubagem');
	fromTo.set('DS', 'Descricao');
	fromTo.set('DT', 'Data');
	fromTo.set('EMAIL', 'Email');
	fromTo.set('END', 'Logradouro');
	fromTo.set('FL', 'Eh');
	fromTo.set('GUID', 'Guid');
	fromTo.set('HR', 'Hora');
	fromTo.set('IBGE', 'Ibge');
	fromTo.set('ID', 'Id');
	fromTo.set('IDENT', 'Identificacao');
	fromTo.set('IE', 'Inscricao_estadual');
	fromTo.set('IM', 'Inscricao_municipal');
	fromTo.set('IMG', 'Imagem');
	fromTo.set('KM', 'Km');
	fromTo.set('LOGIN', 'Login');
	fromTo.set('NM', 'Nome');
	fromTo.set('NR', 'Numero');
	fromTo.set('OBS', 'Observacao');
	fromTo.set('PERC', 'Percentual');
	fromTo.set('PESO', 'Peso');
	fromTo.set('QT', 'Quantidade');
	fromTo.set('QTD', 'Quantidade');
	fromTo.set('RG', 'RG');
	fromTo.set('SENHA', 'Senha');
	fromTo.set('SEQ', 'Sequencia');
	fromTo.set('SIGLA', 'Sigla');
	fromTo.set('TEL', 'Telefone');
	fromTo.set('TOKEN', 'Token');
	fromTo.set('TP', 'Tipo');
	fromTo.set('UF', 'UF');
	fromTo.set('URL', 'Url');
	fromTo.set('VL', 'Valor');

	name = name.toUpperCase().replace(formatScreenName(tableName).toUpperCase(), '');

	const nameFormat = removeDiacritics(name).toUpperCase();
	let nameSplited = nameFormat.split('_');

	if (fromTo?.size > 0) {
		if (fromTo.has(nameSplited[0])) {
			nameSplited[0] = fromTo.get(nameSplited[0])!;

			if (nameSplited[0].indexOf('_') >= 0) {
				name = nameSplited.join('_');

				nameSplited = name.split('_');
			}
		}
	}

	nameSplited = nameSplited.map(item => {
		return upperFirstWord(item.toLocaleLowerCase());
	});

	return nameSplited.join('');
};
