import React, {ReactNode, useEffect, useState} from 'react';
import {IApplication, ITemplateMenu} from 'src/@types/app';
import MenuBar from './components/MenuBar';
import TopBar from './components/TopBar';
import * as S from './styles';

type Props = {
	children: ReactNode;
	pageName?: string;
	application: IApplication;
};

const NiftyLayout = ({children, pageName, application}: Props) => {
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const [templateMenu, setTemplateMenu] = useState<ITemplateMenu[]>();

	useEffect(() => {
		const templateMenuData: ITemplateMenu[] = [];

		application?.folders
			?.filter(item => item.menu?.use)
			.forEach(item =>
				templateMenuData.push({
					name: item.menu?.name ?? '',
					path: item.path ?? '',
					type: 'folder',
					icon: item.menu?.icon,
					regularName: item.name,
					ref: item.ref,
				}),
			);
		application?.resources
			?.filter(item => item.menu?.use)
			.forEach(item =>
				templateMenuData.push({
					name: item.menu?.name ?? '',
					path: item.path ?? '',
					type: 'component',
					icon: item.menu?.icon,
					regularName: item.name,
					ref: item.ref,
				}),
			);

		setTemplateMenu(templateMenuData);
	}, [application]);

	return (
		<S.Main>
			<S.BackGround>
				<S.BackGroundTop
					backTopColor={application?.layout?.templateConfig?.Layout?.backTop}
					titleColor={application?.layout?.templateConfig?.Layout?.title}
				/>
				<S.BackGroundBottom
					backTopColor={application?.layout?.templateConfig?.Layout?.backBottom}
					titleColor={application?.layout?.templateConfig?.Layout?.title}
				/>
			</S.BackGround>
			<S.Container>
				<TopBar isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} app={application} />
				<S.Body>
					<MenuBar isOpen={isOpenMenu} menuData={templateMenu ?? []} app={application} />
					<S.FormArea isOpen={isOpenMenu}>
						<S.ContentTop>
							<S.Title
								backTopColor={
									application?.layout?.templateConfig?.Layout?.backBottom
								}
								titleColor={application?.layout?.templateConfig?.Layout?.title}>
								{pageName ?? ''}
							</S.Title>
						</S.ContentTop>
						<S.ContentComponents>{children}</S.ContentComponents>
					</S.FormArea>
				</S.Body>
			</S.Container>
		</S.Main>
	);
};

export default NiftyLayout;
