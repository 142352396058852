import styled, {css} from 'styled-components';
import {applicationTheme} from 'src/shared/theme';

type CheckBoxContainerProps = {
	inputType?: 'default' | 'undeline' | 'light';
	search?: boolean;
};
export const CheckboxContainer = styled.div<CheckBoxContainerProps>`
	margin-right: 19px;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: ${props => (props.inputType === 'light' ? '38px' : 'auto')};

	svg path {
		fill: ${applicationTheme.gray[400]};
	}
`;

type TextProps = {
	inputType?: 'default' | 'undeline' | 'light';
	textColor?: string;
	fontWeight?: string;
};

export const Text = styled.div<TextProps>`
	${({inputType, textColor, fontWeight}) => css`
		font-size: 14px;
		font-weight: ${inputType === 'light' ? '400' : fontWeight ? fontWeight : '400'};
		line-height: 24px;
		margin-left: ${inputType === 'light' ? '0px !important' : '15px'};
		color: ${inputType === 'light' ? '#000' : textColor ? textColor : '#000'};
	`}
`;

export const ContentIcon = styled.div`
	margin-top: 4px;
`;
