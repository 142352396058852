/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {TemplateListData} from 'src/helpers/select-data/templateList';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemTextArea} from 'src/shared/components/System/SystemTextArea';
import {WidthBox, Wrapper, Text, Button} from 'src/shared/components/UI';
import * as S from './styles';
import {PhotoshopPicker} from 'react-color';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {Accordion} from 'src/shared/components/UI/Accordion';
import {ApplicationContext} from 'src/context';
import FeatherIcon from 'feather-icons-react';
import {FileUploader} from 'react-drag-drop-files';
import {applicationTheme} from 'src/shared/theme';
import {getTemplateComponent, getTemplateConfig} from 'src/templates/template-config';

export const RenderLayout = () => {
	const [data, setData] = useContext(ApplicationContext);
	const [isOpen, setIsOpen] = useState<any>({});
	const [colorX, setColorX] = useState(0);
	const [colorY, setColorY] = useState(0);
	const [oldColor, setOldColor] = useState('');
	const [changedTemplate, setChangedTemplate] = useState(false);

	const colorSubProperties = [
		'textColor',
		'background',
		'backTop',
		'backBottom',
		'title',
		'icon',
	];

	useEffect(() => {
		const objData: any = {};

		if (!!data && !!data.layout && !!data.layout.templateConfig) {
			Object.keys(data?.layout?.templateConfig).forEach(key => {
				Object.keys(data?.layout?.templateConfig[key] ?? {}).forEach(subKey => {
					if (colorSubProperties.includes(subKey)) {
						if (!objData[key]) objData[key] = {};
						objData[key][subKey] = false;
					}
				});
			});
		}

		setIsOpen(objData);
	}, [changedTemplate]);

	const getProperties = (): string[] => {
		return Object.keys(data?.layout?.templateConfig ?? {});
	};

	const getSubProperties = (key: string): string[] => {
		if (data?.layout?.templateConfig)
			return Object.keys(data?.layout?.templateConfig[key] ?? {});
		return [];
	};

	const getComponents = (key: string, subKey: string) => {
		const inputTypes = ['default', 'undeline', 'light', 'minimal'];
		const position = ['left', 'right'];

		if (subKey === 'inputType') {
			return (
				<SystemSelect
					value={data?.layout?.templateConfig[key][subKey]}
					options={inputTypes.map(x => ({
						label: x,
						value: x,
					}))}
					onChange={val =>
						setData({
							...data,
							layout: {
								...(data?.layout ?? {}),
								templateConfig: {
									...(data?.layout?.templateConfig ?? {}),
									[key]: {
										...data?.layout?.templateConfig[key],
										[subKey]: val.value,
									},
								},
							},
						})
					}
				/>
			);
		}
		if (subKey === 'iconPosition') {
			return (
				<SystemSelect
					value={data?.layout?.templateConfig[key][subKey]}
					options={position.map(x => ({
						label: x,
						value: x,
					}))}
					onChange={val =>
						setData({
							...data,
							layout: {
								...(data?.layout ?? {}),
								templateConfig: {
									...(data?.layout?.templateConfig ?? {}),
									[key]: {
										...data?.layout?.templateConfig[key],
										[subKey]: val.value,
									},
								},
							},
						})
					}
				/>
			);
		}
		if (colorSubProperties.includes(subKey)) {
			return (
				<S.ValueInput>
					<S.ColorButton
						background={data?.layout?.templateConfig[key][subKey]}
						onClick={e => {
							setOldColor(data?.layout?.templateConfig[key][subKey]);
							setIsOpen({
								...isOpen,
								[key]: {
									...isOpen[key],
									[subKey]: !isOpen[key][subKey],
								},
							});
							setColorX(e.pageX - 500);
							setColorY(e.pageY);
						}}
					/>
					{isOpen && isOpen[key] && isOpen[key][subKey] ? (
						<S.PickerContent top={colorY} left={colorX}>
							<PhotoshopPicker
								color={data?.layout?.templateConfig[key][subKey]}
								onChangeComplete={color => {
									setData({
										...data,
										layout: {
											...(data?.layout ?? {}),
											templateConfig: {
												...(data?.layout?.templateConfig ?? {}),
												[key]: {
													...data?.layout?.templateConfig[key],
													[subKey]: color.hex,
												},
											},
										},
									});
								}}
								onAccept={() => {
									setIsOpen({
										...isOpen,
										[key]: {
											...isOpen[key],
											[subKey]: !isOpen[key][subKey],
										},
									});
								}}
								onCancel={() => {
									setData({
										...data,
										layout: {
											...(data?.layout ?? {}),
											templateConfig: {
												...(data?.layout?.templateConfig ?? {}),
												[key]: {
													...data?.layout?.templateConfig[key],
													[subKey]: oldColor,
												},
											},
										},
									});
									setIsOpen({
										...isOpen,
										[key]: {
											...isOpen[key],
											[subKey]: !isOpen[key][subKey],
										},
									});
								}}
							/>
						</S.PickerContent>
					) : (
						<></>
					)}
				</S.ValueInput>
			);
		}

		return (
			<SystemInput
				value={data?.layout?.templateConfig[key][subKey]}
				onChange={val =>
					setData({
						...data,
						layout: {
							...(data?.layout ?? {}),
							templateConfig: {
								...(data?.layout?.templateConfig ?? {}),
								[key]: {
									...data?.layout?.templateConfig[key],
									[subKey]: val,
								},
							},
						},
					})
				}
			/>
		);
	};

	const handleUploadLogo = async (file: any) => {
		const fileBase64 = await convertToBase64(file);
		const base64 = fileBase64.replace('data:application/json;base64,', '');
		const fileData = base64.replace('data:application/json;base64,', '');

		setData({
			...data,
			layout: {
				...data?.layout,
				logo: fileData,
			},
		});
	};

	const convertToBase64 = (file: any): Promise<string> =>
		new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
		});

	return (
		<S.Content>
			<S.Tag>
				<div className="text">Layout</div>
			</S.Tag>
			<S.Card>
				<S.LayoutContent>
					<div className="left">
						<S.Tag>
							<FeatherIcon icon="layout" size={20} />
							<div className="text">Tipo do layout</div>
						</S.Tag>
						{TemplateListData.map((item, index) => (
							<S.LayoutItem
								key={index}
								onClick={() => {
									const config = getTemplateConfig(item.value);
									const templateData = getTemplateComponent(item.value as any);
									setData({
										...data,
										layout: {
											...data.layout,
											templateConfig: config,
											templateName: item.value,
										},
										template: templateData,
									});
									setChangedTemplate(!changedTemplate);
								}}>
								{item.value === data.layout?.templateName ? (
									<div className="bold">{item.label}</div>
								) : (
									item.label
								)}
							</S.LayoutItem>
						))}
					</div>
					<div className="right">
						<S.Tag>
							<div className="text">Exemplos de layout</div>
						</S.Tag>
					</div>
				</S.LayoutContent>
			</S.Card>

			<S.Margin />
			<S.Tag>
				<div className="text">Logotipo</div>
			</S.Tag>
			<S.Card>
				<S.ContentUpload>
					<FileUploader
						handleChange={handleUploadLogo}
						name="file"
						types={['PNG', 'JPG', 'JPEG']}>
						<div className="content">
							{!data?.layout?.logo ? (
								<></>
							) : (
								<img
									style={{display: 'block', width: '100px', height: '100px'}}
									id="base64image"
									src={data?.layout?.logo}
									className="img"
								/>
							)}
							<Button
								text="Fazer upload"
								leftIcon2="upload-cloud"
								onClick={() => console.log()}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
							<div className="text">tamanho máximo de 10MB</div>
						</div>
					</FileUploader>
				</S.ContentUpload>
			</S.Card>

			<S.Margin />
			<S.Tag>
				<div className="text">Cores</div>
			</S.Tag>

			{getProperties().map((key, index) => (
				<Accordion
					key={index}
					title={key}
					body={
						<>
							{getSubProperties(key).map((subKey, index2) => (
								<Wrapper
									width="100%"
									key={index2}
									justifyContent="center"
									alignItems="center"
									margin="0 0 2px 0">
									<WidthBox width="47%">
										<Text text={subKey} />
									</WidthBox>
									<WidthBox width="47%">{getComponents(key, subKey)}</WidthBox>
								</Wrapper>
							))}
						</>
					}
				/>
			))}

			<S.Margin />
			<S.Tag>
				<div className="text">Tags</div>
			</S.Tag>

			<S.Card>
				<Wrapper margin="15px 0 0 0">
					<SystemTextArea
						value={data?.layout?.headerTags}
						label="Header Tags (script, style, meta)"
						onChange={val =>
							setData({...data, layout: {...data?.layout, headerTags: val}})
						}
					/>
					<SystemTextArea
						value={data?.layout?.footerTags}
						label="Footer Tags (script, style, meta)"
						onChange={val =>
							setData({...data, layout: {...data?.layout, footerTags: val}})
						}
					/>
				</Wrapper>
			</S.Card>
		</S.Content>
	);
};
