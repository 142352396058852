/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import Layout from 'src/shared/components/System/Layout';
import {LeftBarRender} from './components/LeftBarRender';
import {AddTopFunctionBar} from './components/AddTopFunctionBar';
import * as S from './styles';
import {ApplicationContext, LoadingContext} from 'src/context';
import {IColumn, IDataFile, IDatabaseConfig, ITable} from 'src/@types/app';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {Chip, MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {Line, WidthBox, Wrapper, Text, ModalTitle, Button} from 'src/shared/components/UI';
import {DatabaseTypeList} from 'src/helpers/select-data/databaseTypeList';
import {RightBarRender} from './components/RightBarRender';
import {AddField} from './components/AddField';
import {FileUploader} from 'react-drag-drop-files';
import {convertDataFileToDatabase} from 'src/helpers/methods/data-file-to-database';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {useSnackbar} from 'notistack';
import {NotFoundDataBase} from '../project/ui/NotFoundDataBase';
import {TransformToEndpoints} from 'src/helpers/methods/database-to-screen';
import {applicationTheme} from 'src/shared/theme';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';
import {propertyNameForBackend} from 'src/shared/engine-back/common/common-formatters';

interface CellType {
	row: IColumn;
}

const DatabaseScreen = () => {
	const {enqueueSnackbar} = useSnackbar();

	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);
	const [data, setData] = useState<ITable>();
	const [database, setDatabase] = useState<IDatabaseConfig>();
	const [selectedColumn, setSelectedColumn] = useState<IColumn>();
	const [isOpenEditColumn, setIsOpenEditColumn] = useState(false);
	const [changed, setChanged] = useState(0);
	const [isOpenDelete, showIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IColumn | null>(null);

	useEffect(() => {
		setDatabase(application.database?.databases?.find(db => db.selected));
		setData(application.database?.tables?.find(table => table.selected));
		setChanged(changed + 1);
	}, [application]);

	const handleDeleteColumn = (row: IColumn) => {
		setApplication({
			...application,
			database: {
				...application.database,
				tables: application.database?.tables?.map(table => {
					if (table.selected) {
						const columns = [...(table.columns ?? [])].filter(
							item => item.ref != row.ref,
						);
						table.columns = columns;
					}

					return table;
				}),
			},
		});
	};

	const handleEditColumn = (row: IColumn) => {
		setSelectedColumn(row);
		setIsOpenEditColumn(true);
	};

	const openDeleteModal = (item: IColumn) => {
		setItemToDelete(item);
		showIsOpenDelete(true);
	};

	const onRemove = () => {
		if (itemToDelete) {
			handleDeleteColumn(itemToDelete);
			setItemToDelete(null); // Limpa o item após a exclusão
			showIsOpenDelete(false);
		}
	};

	const handleChange = async (file: any) => {
		const fileBase64 = await convertToBase64(file);
		const base64 = fileBase64.replace('data:application/json;base64,', '');
		const jsonData: IDataFile[] = JSON.parse(atob(base64));
		const databaseRef = application?.database?.databases?.find(x => x.selected)?.ref;
		if (!databaseRef) return undefined;
		const data = convertDataFileToDatabase(jsonData, databaseRef);
		if (!data) return;

		setApplication({
			...application,
			database: {
				...application?.database,
				tables: data.tables,
			},
		});
	};

	const convertToBase64 = (file: any): Promise<string> =>
		new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result as string);
		});

	const saveDataBase = async () => {
		if (!application || !application.id) return;

		try {
			setLoading(true);

			application?.database?.tables?.forEach((table, indexTable) => {
				table?.columns?.forEach((column, indexColumn) => {
					column.internalName = propertyNameForBackend(table.name!, column.name!);
				});
			});

			const apis = TransformToEndpoints(application);

			await new ApplicationApi().updateComplex({
				id: application.id,
				database: application?.database ? JSON.stringify(application.database) : undefined,
				apis: JSON.stringify(apis),
				databaseRules: application?.databaseRules
					? JSON.stringify(application.databaseRules)
					: undefined,
				contextEnvironment: application.userConfiguration
					? JSON.stringify(application.userConfiguration)
					: undefined,
				environments: application.environments
					? JSON.stringify(application.environments)
					: undefined,
				amqps: application.amqps ? JSON.stringify(application.amqps) : undefined,
				caches: application.caches ? JSON.stringify(application.caches) : undefined,
				emails: application.emails ? JSON.stringify(application.emails) : undefined,
				ftps: application.ftps ? JSON.stringify(application.ftps) : undefined,
			});

			enqueueSnackbar('Registro salvo com sucesso', {variant: 'success'});
			setChanged(0);
			setApplication({...application, apis: apis});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Layout
			expanded={false}
			additionalBarContent={<LeftBarRender />}
			save={saveDataBase}
			messageWarning={changed > 1 ? 'Não se esqueça de salvar' : undefined}>
			{database && !data ? (
				<S.UploadContent>
					<FileUploader handleChange={handleChange} name="file" types={['JSON']}>
						<div className="item">
							<FeatherIcon icon="upload" size={40} />
						</div>
					</FileUploader>
					<div className="text">Upload de tabelas</div>
				</S.UploadContent>
			) : (
				<></>
			)}
			{!application ||
			!application.database ||
			!application.database.databases ||
			application.database.databases.length == 0 ? (
				<NotFoundDataBase />
			) : undefined}
			{data ? (
				<S.Content>
					<AddTopFunctionBar />

					<Wrapper gap="0" alignItems="start">
						<S.ContentGrid>
							<SystemDataGrid
								rowsData={data.columns ?? []}
								columns={[
									{
										flex: 0.02,
										minWidth: 90,
										type: 'actions',
										filterable: false,
										hideable: false,
										headerAlign: 'left',
										align: 'left',
										sortable: false,
										field: 'actions',
										headerName: '',
										renderCell: ({row}: CellType) => (
											<SystemRowOptions
												childrens={
													<>
														<MenuItem
															onClick={() => handleEditColumn(row)}
															sx={{'& svg': {mr: 2}}}>
															<FeatherIcon icon="edit-3" size={16} />
															<Text text="Editar" />
														</MenuItem>
														<Wrapper
															alignItems="center"
															justifyContent="center">
															<WidthBox width="90%">
																<Line
																	height="0.5px"
																	background={
																		applicationTheme.gray[300]
																	}
																/>
															</WidthBox>
														</Wrapper>
														<MenuItem
															onClick={() => openDeleteModal(row)}
															sx={{'& svg': {mr: 2}}}>
															<FeatherIcon icon="trash-2" size={16} />
															<Text
																text="Excluir"
																color={applicationTheme.error[900]}
															/>
														</MenuItem>
													</>
												}
											/>
										),
									},
									{
										flex: 0.2,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'name',
										headerName: `Nome`,
										renderCell: ({row}: CellType) => row.name,
									},
									{
										flex: 0.08,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'type',
										headerName: `Tipo`,
										renderCell: ({row}: CellType) =>
											DatabaseTypeList.find(x => x.value === row.type)?.label,
									},
									{
										flex: 0.05,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'isPrimaryKey',
										headerName: `Chave primária`,
										renderCell: ({row}: CellType) =>
											row.isPrimaryKey ? (
												<Chip label="Sim" color="success" />
											) : (
												<Chip label="Não" color="info" />
											),
									},
									{
										flex: 0.05,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'nullable',
										headerName: `Permite vazio`,
										renderCell: ({row}: CellType) =>
											row.nullable ? (
												<Chip label="Sim" color="success" />
											) : (
												<Chip label="Não" color="info" />
											),
									},
									{
										flex: 0.1,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'constraint',
										headerName: `Relacionamento`,
										renderCell: ({row}: CellType) => {
											if (row.constraint) {
												return (
													application.database?.tables?.find(
														tb => tb.ref === row.constraint,
													)?.name ?? ''
												);
											}
											return '';
										},
									},
								]}
							/>
						</S.ContentGrid>

						<RightBarRender />
					</Wrapper>
				</S.Content>
			) : (
				<></>
			)}

			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar campo?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este campo? <br />
						Essa ação não pode ser desfeita.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<AddField
				isOpen={isOpenEditColumn}
				setIsOpen={setIsOpenEditColumn}
				selectedItem={selectedColumn}
			/>
		</Layout>
	);
};

export default DatabaseScreen;
