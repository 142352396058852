/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {Line, Select, SizedBox, Text, WidthBox, Wrapper} from 'src/shared/components/UI';
import * as S from '../styles';
import {IExpression, IOption, IWorkFlowItem} from 'src/@types/app';
import {getOptionFieldsList, setWorkFlowValues} from '../helper';
import ExpressionItem from 'src/pages/builder/Builder/components/Expression/ExpressionItem';
import {ApplicationContext} from 'src/context';
import {formatScreenName} from 'src/helpers/methods/database-to-screen';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {lowerFirstWord} from 'src/helpers/methods/text-methods';

export enum CrudOptions {
	Insert,
	Update,
	Delete,
	Get,
	List,
}

export type CrudComponentType = {
	propRef?: string;
	propName?: string;
	fieldRef?: string;
	fieldName?: string;
	subFieldRef?: string;
	subFieldName?: string;
	subFieldOptions?: IOption[];
};

const listCrudOptions: IOption[] = [
	{value: undefined, label: 'Selecione...'},
	{value: CrudOptions.Insert, label: 'Inserir'},
	{value: CrudOptions.Update, label: 'Alterar'},
	{value: CrudOptions.Delete, label: 'Remover'},
	{value: CrudOptions.Get, label: 'Obter'},
	{value: CrudOptions.List, label: 'Listar'},
];

type Props = {
	item: IWorkFlowItem;
	workFlowItem: IWorkFlowItem[];
	setWorkFlowItem: (value: IWorkFlowItem[]) => void;
};

export const CrudComp = ({item, workFlowItem, setWorkFlowItem}: Props) => {
	const [application] = useContext(ApplicationContext);

	const [tableOptions, setTableOptions] = useState<IOption[]>([]);
	const [originRef, setOriginRef] = useState<string>();
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);

	useEffect(() => {
		const options: IOption[] = [
			{
				value: undefined,
				label: 'Selecione...',
			},
		];

		application.database?.tables?.forEach(table => {
			options.push({
				value: table.ref,
				label: table.name ?? '',
			});
		});

		setTableOptions(options);
		setFieldOptions(getOptionFieldsList(application, workFlowItem, item));
		if (item.values?.tableRef) setOriginRef(item.values?.tableRef);
	}, [application]);

	const saveExp = (data: IExpression) => {
		setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'expression', data);
	};

	const buildPayload = (type: CrudOptions) => {
		const content: JSX.Element[] = [];

		application.database?.tables
			?.find(x => x.ref === item.values?.tableRef)
			?.columns?.forEach(column => {
				if (type === CrudOptions.Insert && column.isPrimaryKey && !column.constraint)
					return;
				if (type === CrudOptions.Delete && (!column.isPrimaryKey || column.constraint))
					return;

				const itemDataMap: CrudComponentType | undefined = item.values?.dataMap?.find(
					(c: CrudComponentType) => c.propRef === column.ref,
				);
				content.push(
					<>
						<Wrapper>
							<WidthBox width="50%">
								<Text text={backFormatPropertyName(column.name ?? '')} />
							</WidthBox>
							<WidthBox width="50%">
								<Wrapper gap="2px" flexWrap="wrap">
									<WidthBox
										width={itemDataMap?.subFieldOptions ? '50%' : '100%'}
										minWidth="133px">
										<Select
											value={fieldOptions.find(
												y => y.value == itemDataMap?.fieldRef,
											)}
											options={fieldOptions}
											onChange={val => {
												const isTableKey =
													column.isPrimaryKey && !column.constraint;

												let subPropOptions: any = undefined;
												if (val.custom?.ident === 'TABLE_PROPS')
													subPropOptions = val.custom.value2;

												const data = [...workFlowItem].map(x => {
													if (x.ref === item.ref) {
														let finded = false;
														const dataMap: CrudComponentType[] = [
															...(x.values.dataMap ?? []).map(
																(mapItem: CrudComponentType) => {
																	if (
																		mapItem.propRef ===
																		column.ref
																	) {
																		finded = true;
																		mapItem.fieldRef =
																			val.value;
																		mapItem.fieldName =
																			val.label;
																		mapItem.subFieldOptions =
																			subPropOptions;
																	}
																	return mapItem;
																},
															),
														];

														if (!finded) {
															dataMap.push({
																propRef: column.ref,
																propName: column.internalName ?? '',
																fieldRef: val.value,
																fieldName: val.label,
																subFieldOptions: subPropOptions,
															});
														}

														x.values = {
															...(x.values ?? {}),
															dataMap,
														};
													}
													return x;
												});

												setWorkFlowItem(data);
											}}
											label="Valor"
											background="transparent"
											inputType="minimal"
										/>
									</WidthBox>
									{itemDataMap?.subFieldOptions ? (
										<WidthBox width="49%" minWidth="133px">
											<Select
												value={itemDataMap?.subFieldOptions?.find(
													y => y.value == itemDataMap.subFieldRef,
												)}
												options={itemDataMap?.subFieldOptions ?? []}
												onChange={(val: IOption) => {
													const data = [...workFlowItem].map(x => {
														if (x.ref === item.ref) {
															const dataMap: CrudComponentType[] = [
																...(x.values.dataMap ?? []).map(
																	(
																		mapItem: CrudComponentType,
																	) => {
																		if (
																			mapItem.propRef ===
																			column.ref
																		) {
																			mapItem.subFieldRef =
																				val.value;
																			mapItem.subFieldName =
																				val.label;
																		}
																		return mapItem;
																	},
																),
															];

															x.values = {
																...(x.values ?? {}),
																dataMap,
															};
														}
														return x;
													});

													setWorkFlowItem(data);
												}}
												label="Propriedade"
												background="transparent"
												inputType="minimal"
											/>
										</WidthBox>
									) : (
										<></>
									)}
								</Wrapper>
							</WidthBox>
						</Wrapper>
					</>,
				);
			});

		return content;
	};

	const getReturnVarName = (operation: CrudOptions, tableRef: string) => {
		if (
			operation != null &&
			operation != undefined &&
			tableRef &&
			![CrudOptions.Delete, CrudOptions.Update].includes(operation)
		) {
			let varName = formatScreenName(
				application.database?.tables?.find(x => x.ref === tableRef)?.name ?? '',
			);
			varName = lowerFirstWord(varName);
			if (operation === CrudOptions.List) varName += 'List';
			varName = checkReturnVarName(varName, 1);

			setWorkFlowValues(workFlowItem, setWorkFlowItem, item.ref!, 'variableName', varName);
		}
	};

	const checkReturnVarName = (varName: string, acc: number): string => {
		const resultFind = workFlowItem.find(x => x.values?.variableName === varName);
		if (resultFind) {
			const newVarName = varName + acc;
			const resultFind2 = workFlowItem.find(x => x.values?.variableName === newVarName);
			if (resultFind2) {
				acc++;
				return checkReturnVarName(varName, acc);
			}
			return newVarName;
		}
		return varName;
	};

	return (
		<S.ContentBasic>
			<Select
				value={listCrudOptions.find(y => y.value == item.values?.operation)}
				options={listCrudOptions}
				onChange={val => {
					setWorkFlowValues(
						workFlowItem,
						setWorkFlowItem,
						item.ref!,
						'operation',
						val.value,
					);
					getReturnVarName(val.value, item.values?.tableRef);
				}}
				label="Operação"
				background="transparent"
				inputType="minimal"
			/>

			{item.values?.operation != undefined ? (
				<>
					<Select
						value={tableOptions.find(y => y.value == item.values?.tableRef)}
						options={tableOptions}
						onChange={val => {
							setWorkFlowValues(
								workFlowItem,
								setWorkFlowItem,
								item.ref!,
								'tableRef',
								val.value,
							);
							setOriginRef(val.value);
							getReturnVarName(item.values?.operation, val.value);
						}}
						label="Tabela"
						background="transparent"
						inputType="minimal"
					/>
				</>
			) : (
				<></>
			)}

			{[CrudOptions.Insert, CrudOptions.Update, CrudOptions.Delete].includes(
				item.values?.operation,
			) ? (
				<>
					<Text text="Payload" />
					<Line background="#ccc" height="0.5px" />
					<SizedBox height="5px" />
					{buildPayload(item.values?.operation)}
				</>
			) : (
				<></>
			)}

			{item.values?.operation !== CrudOptions.Delete &&
			item.values?.operation !== CrudOptions.Update &&
			item.values?.tableRef ? (
				<>
					<Text text="Nome da variável de retorno" />
					<Line background="#ccc" height="0.5px" />
					<SizedBox height="5px" />
					<Text text={item.values?.variableName} fontWeight="bold" />
				</>
			) : (
				<></>
			)}

			{(item.values?.operation === CrudOptions.Get ||
				item.values?.operation === CrudOptions.List) &&
			item.values?.tableRef ? (
				<>
					<SizedBox height="10px" />
					<Text text="Filtro" />
					<Line background="#ccc" height="0.5px" />
					<SizedBox height="3px" />
					<ExpressionItem
						currentItem={item?.values?.expression ?? {}}
						customSave={saveExp}
						inputType="minimal"
						useWorkflow={true}
						showComparetor={true}
						showDataProp={true}
						originRef={originRef}
					/>
				</>
			) : (
				<></>
			)}
		</S.ContentBasic>
	);
};
