/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {TextArea, Wrapper} from '../../UI';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	value?: string;
	onChange: (value: string) => void;
	onKeyDown?: (event: any) => void;
	label?: string;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	search?: boolean;
	rows?: number;
	width?: string;
	margin?: string;
	maxWidth?: string;
};

export const SystemTextArea = ({
	value = '',
	onChange,
	onKeyDown,
	label,
	placeholder,
	required,
	disabled,
	search,
	rows = 2,
	margin,
	maxWidth,
	width,
}: Props) => {
	return (
		<Wrapper width={width} margin={margin} maxWidth={maxWidth}>
			<TextArea
				value={value}
				onChange={onChange}
				onKeyDown={onKeyDown}
				label={label}
				placeholder={placeholder}
				border={`1px solid ${applicationTheme.gray[300]}`}
				inputType="light"
				labelColor={applicationTheme.gray[700]}
				required={required}
				disabled={disabled}
				search={search}
				rows={rows}
			/>
		</Wrapper>
	);
};
