import {DatabaseColumnEnum} from 'src/@types/enums';

export const mapTypeDatabase = (type: string) => {
	switch (type.toUpperCase()) {
		case 'VARCHAR':
		case 'NVARCHAR':
		case 'CHAR':
			return DatabaseColumnEnum.String;
		case 'BOOLEAN':
		case 'BIT':
			return DatabaseColumnEnum.Boolean;
		case 'DATE':
			return DatabaseColumnEnum.DateOnly;
		case 'DATETIME':
			return DatabaseColumnEnum.Date;
		case 'SMALLDATETIME':
			return DatabaseColumnEnum.DateTime;
		case 'SMALLINT':
			return DatabaseColumnEnum.Int16;
		case 'INT':
			return DatabaseColumnEnum.Int32;
		case 'BIGINT':
			return DatabaseColumnEnum.Int64;
		case 'TINYINT':
			return DatabaseColumnEnum.Byte;
		case 'DECIMAL':
			return DatabaseColumnEnum.Decimal;
	}
};

export const mapToDotnet = (type: DatabaseColumnEnum): string => {
	switch (type) {
		case DatabaseColumnEnum.String:
			return 'string';
		case DatabaseColumnEnum.Boolean:
			return 'bool';
		case DatabaseColumnEnum.DateOnly:
		case DatabaseColumnEnum.Date:
		case DatabaseColumnEnum.DateTime:
			return 'DateTime';
		case DatabaseColumnEnum.Decimal:
			return 'decimal';
		case DatabaseColumnEnum.Byte:
			return 'byte';
		case DatabaseColumnEnum.Int16:
			return 'short';
		case DatabaseColumnEnum.Int32:
			return 'int';
		case DatabaseColumnEnum.Int64:
			return 'long';
		default:
			throw new Error(`Tipo de coluna desconhecido: ${type}`);
	}
};
