import {IApplication, IComponent} from 'src/@types/app';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';
import {STATE_OBJECTS} from '../engine-enum';
import {ComponentComplexEnum, DatabaseColumnEnum, DatabaseMaskEnum} from 'src/@types/enums';

export const addState = (
	app: IApplication,
	widget: IComponent,
	documentsState: string[],
	documentType: string[],
	documentErrorType: string[],
) => {
	const objectItem = STATE_OBJECTS.find(x => x.name === widget.name);

	if (objectItem) {
		let objDataType = 'string';
		const objName = widget.properties['name'];

		if (objectItem.type === 'string') {
			const objMask = widget.properties['mask'];

			if (objMask !== undefined) {
				//if (objMask === DatabaseMaskEnum.Numero || objMask === DatabaseMaskEnum.Monetario) {
				if (objMask === DatabaseMaskEnum.Numero) {
					objDataType = 'number';
				}
			}
		} else {
			objDataType = objectItem.type;
		}

		if (widget?.icon === 'list') {
			const tabRef = app.database?.tables?.find(tab =>
				tab.columns?.find(col => col.ref === widget.columnRef),
			);
			const columnRef = app.database?.tables
				?.find(x => x.ref === tabRef?.ref)
				?.columns?.find(x => x.ref == widget.columnRef);

			if (columnRef?.type === DatabaseColumnEnum.Boolean) {
				objDataType = 'boolean';
			} else if (
				columnRef?.type === DatabaseColumnEnum.Byte ||
				columnRef?.type === DatabaseColumnEnum.Int16 ||
				columnRef?.type === DatabaseColumnEnum.Int32 ||
				columnRef?.type === DatabaseColumnEnum.Int64 ||
				columnRef?.type === DatabaseColumnEnum.Decimal
			) {
				objDataType = 'number';
			} else if (
				columnRef?.type === DatabaseColumnEnum.Date ||
				columnRef?.type === DatabaseColumnEnum.DateOnly ||
				columnRef?.type === DatabaseColumnEnum.DateTime
			) {
				objDataType = 'Date';
			}
		}

		const documentTypeInclude =
			objectItem.name === ComponentComplexEnum.DataGrid
				? `${objName.replace('grid', '')}?: ${objDataType};`
				: `${objName}?: ${objDataType};`;
		const documentErrorTypeInclude =
			objectItem.name === ComponentComplexEnum.DataGrid ? '' : `${objName}?: string;`;

		if (!documentType.includes(documentTypeInclude)) {
			documentType.push(documentTypeInclude);
		}

		if (!documentErrorType.includes(documentErrorTypeInclude)) {
			documentErrorType.push(documentErrorTypeInclude);
		}

		if (objectItem.name === ComponentComplexEnum.DataGrid) {
			documentsState.push(
				`const [${lowerFirstWord(objName)}, set${upperFirstWord(
					objName,
				)}] = useState<any>();`,
			);
		}
	}
};
