/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IAction,
	IActionApiMap,
	IApiData,
	IApiMap,
	IApplication,
	IComponent,
	IColumn,
	IResource,
} from 'src/@types/app';
import {
	ActionTypeEnum,
	ApiMethods,
	ApiParamTypeEnum,
	ComponentComplexEnum,
	ComponentSimpleEnum,
	DatabaseColumnEnum,
	DatabaseMaskEnum,
	GridDataActionEnum,
} from 'src/@types/enums';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';
import {getRepositoryClass, getRepositoryName} from '../repository-generator';
import {resourceRouteFormatted} from '../route-generator';
import {IExpandSearch} from 'src/shared/components/Global/SearchModal';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {dotnetFormatScreenName} from 'src/shared/engine-back/core/dotnet/core/dotnet-formatter';
import {DataInputComponent} from 'src/shared/components';
import {buildWorkFlowCodeInput} from 'src/helpers/workflow/workflowInput/builder/buildWorkFlowCodeInput';
import {act} from 'react-dom/test-utils';

export const addAction = (
	widgets: IComponent[],
	widgetImports: string[],
	apiImports: string[],
	app: IApplication,
	fileInstances: string[],
	instanceContext: string[],
	importContext: string[],
	widget?: IComponent,
	pageActions?: IAction[],
	resource?: IResource,
	effectApiData?: IApiData,
): string => {
	const actions = widget?.actions ?? pageActions;

	if (actions && actions.length > 0) {
		const invokeMethod = widget
			? getInvokeMethod(widget.name as ComponentSimpleEnum)
			: undefined;
		const actionString = invokeMethod
			? `${invokeMethod}={
			async () => {
				{ACTION}
			}}`
			: `{ACTION}`;
		let actionData = '';

		actions.forEach(action => {
			if (action.actionType === ActionTypeEnum.Validation)
				actionData += 'if (!validForm()) return;';
			if (action.actionType === ActionTypeEnum.DataGrid) {
				actionData += addGridAction(action, widgets);
			}
			if (action.actionType === ActionTypeEnum.OpenModal) {
				//TODO: precisa revisitar este item depois
				actionData += `PENDENTE`;
			}
			if (action.actionType === ActionTypeEnum.NextPage) {
				actionData += addNextPageAction(action, app, widgetImports);
			}
			if (action.actionType === ActionTypeEnum.Api) {
				actionData += addApiAction(
					action,
					widgets,
					app,
					apiImports,
					fileInstances,
					instanceContext,
					importContext,
					widgetImports,
					false,
					resource,
				);
			}
			if (action.actionType === ActionTypeEnum.Search) {
				actionData += addSearch(
					action,
					widgets,
					app,
					apiImports,
					fileInstances,
					instanceContext,
					importContext,
					widgetImports,
				);
			}
			if (action.actionType === ActionTypeEnum.Clear) {
				actionData += `clearPage();`;
			}
			if (action.actionType === ActionTypeEnum.SetValue) {
				actionData += addSetValue(action, effectApiData);
			}
		});
		return actionString.replace('{ACTION}', actionData);
	}

	if (widget?.inputActions && widget?.inputActions.length > 0) {
		let code = '';
		widget?.inputActions.forEach(inputAction => {
			const codeAction = buildWorkFlowCodeInput(inputAction.workflow ?? [], app);
			const mActionName = lowerFirstWord(inputAction.inputActionType ?? '');
			const actionName = mActionName == 'onChange' ? 'onChangeValue' : mActionName;
			code += `${actionName}={(val: any) => {
				${codeAction}
			}}`;
		});

		return code;
	}

	return '';
};

export const addDataGridAction = (
	objName: string,
	widget: IComponent,
	widgets: IComponent[],
	actionItem: any,
): string => {
	const defaultReturn = '() => ""';
	const setterProp = `set${upperFirstWord(objName)}`;

	if (actionItem.actionType?.value === GridDataActionEnum.Remove) {
		return `() => setFormData({...formData, ${objName.replace(
			'grid',
			'',
		)}: [...formData?.${objName.replace(
			'grid',
			'',
		)} ?? []].filter(item => item._ref != row._ref)})`;
	}

	//TODO: precisa revisitar este item depois
	if (actionItem.actionType?.value === GridDataActionEnum.Edit) {
		let itemString = '';

		const refBtn = widgets
			?.filter(x => x.name === ComponentSimpleEnum.Button)
			?.find(
				x =>
					x.actions?.find(x => x.actionName == ComponentComplexEnum.DataGrid)
						?.targetRef === widget.ref,
			);
		if (!refBtn) return defaultReturn;

		refBtn.actions
			?.find(x => x.actionName == ComponentComplexEnum.DataGrid)
			?.items?.filter(item => !!item.propertyName)
			?.forEach(item => {
				const targetInputState = widgets.find(x => x.ref === item.componentRef)?.properties
					.name;
				itemString += `${targetInputState}: row.${item.propertyName},`;
			});

		return `
			() => {
				setFormData({...formData, ${itemString}});
				${setterProp}Edit(row);
			}
		`;
	}

	return defaultReturn;
};

const addGridAction = (action: IAction, widgets: IComponent[]): string => {
	const targetState = widgets.find(x => x.ref === action?.targetRef)?.properties['name'];
	const targetInputs: string[] = [];
	let itemString = '';
	let clearString = '';
	let functionString = '';

	if (action?.data?.useData) {
		const propKeys = action.data?.props ? Object.keys(action.data?.props) : [];
		let codeProps = '';

		propKeys.forEach(key => {
			codeProps += `${key}: item?.${action.data?.props[key]},`;
		});

		functionString = `
			set${upperFirstWord(targetState)}([...(${action.data?.apiData?.label}?.${
			action.data?.apiData?.prop
		} ?? []).map((item: any) => {
				return {
					${codeProps}
				};
			})]);
		`;
	} else {
		action?.items
			?.filter(item => !!item && !!item.propertyName)
			?.forEach(item => {
				const targetInputState = widgets.find(x => x.ref === item.componentRef)?.properties[
					'name'
				];
				itemString += `${item.propertyName}: formData?.${targetInputState},`;
				targetInputs.push(targetInputState);
			});

		targetInputs.forEach(item => {
			clearString += `${item}: undefined,`;
		});

		const keyColumns = widgets
			.find(x => x.ref === action?.targetRef)
			?.properties.dataColumns.filter((column: any) => !!column.isKey)
			.map((column: any) => `'${column.field}'`);

		if (keyColumns?.length === 0) {
			keyColumns.push("'_ref'");
		}

		functionString = `
			let gridData: any[] = [...(formData?.${targetState.replace('grid', '')} ?? [])];
			const keyColumns = [${keyColumns.join(',')}];

			const gridDataItem: any = {
				_ref: crypto.randomUUID(),
				${itemString}
			};

			if (
				!!keyColumns &&
				keyColumns.length > 0 &&
				gridData.find(gridItem =>
					keyColumns.find(key => gridItem[key] === gridDataItem[key]),
				)
			)
				return;

			if (!!${lowerFirstWord(targetState)}) {
				gridData = [...gridData].map(item => {
					if (item._ref === ${lowerFirstWord(targetState)}._ref) return gridDataItem;
					return item;
				});
			}
			else {
				gridData.push(gridDataItem);
			}
			setFormData({...formData, ${targetState.replace('grid', '')}: gridData, ${clearString}});
			set${upperFirstWord(targetState)}(undefined);
		`;
	}

	return functionString;
};

const loadSearchDependencies = (
	componentProperties: any,
	widgets: IComponent[],
	app: IApplication,
	codeData = '',
) => {
	componentProperties.dependencyList.forEach((dependencyItem: any) => {
		const propName = backFormatPropertyName(dependencyItem.label);
		const parentComponent = widgets.find(x => x.properties.name === propName)?.properties;
		if (parentComponent?.finderData) {
			const mTable = app.database?.tables?.find(
				x => x.ref === parentComponent.finderData?.tableRef,
			);
			const mRefId = mTable?.columns?.find(
				x => x.ref === parentComponent.finderData?.propData?.id,
			)?.name;
			const mRefName = mTable?.columns?.find(
				x => x.ref === parentComponent.finderData?.propData?.value,
			)?.name;
			codeData += `{
				tableName: '${dotnetFormatScreenName(mTable?.name ?? '')}',
				refId: '${backFormatPropertyName(mRefId ?? '')}',
				refName: '${backFormatPropertyName(mRefName ?? '')}',
			},`;

			if (parentComponent.dependencyList && parentComponent.dependencyList.length > 0)
				codeData = loadSearchDependencies(parentComponent, widgets, app, codeData);
		}
	});

	return codeData;
};

const existsParentRef: any = (parentRef: string, widgets: IComponent[], has?: boolean) => {
	if (!has) has = false;
	const parentRefComp = widgets.find(x => x.ref == parentRef);
	if (!!parentRefComp) {
		has = true;
		if (!!parentRefComp.parentRef)
			return existsParentRef(parentRefComp.parentRef, widgets, has);
	} else has = false;

	return has;
};

const addSearch = (
	action: IAction,
	widgets: IComponent[],
	app: IApplication,
	apiImports: string[],
	fileInstances: string[],
	instanceContext: string[],
	importContext: string[],
	widgetImports: string[],
) => {
	const getSet = getUseSearch(action, importContext, instanceContext);
	let components = widgets.filter(widget => DataInputComponent.includes(widget.name));
	components = components?.filter(
		widget =>
			(widget.parentLevel && existsParentRef(widget.parentRef!, widgets)) ||
			!widget.parentLevel,
	);
	components = components?.filter(widget => widget?.properties?.search === true);

	let props = Array.from(
		new Set(components.map(x => `'${x.properties.label ?? x.properties.text}'`)),
	);
	const mComponents: IComponent[] = [];
	components.forEach(component => {
		if (
			!mComponents.find(
				x =>
					(x.properties.label ?? x.properties.text) ===
					(component.properties.label ?? component.properties.text),
			)
		)
			mComponents.push(component);
	});
	const listExpand: IExpandSearch[] = action.data.listExpand;
	let listExpandString = '';

	listExpand
		.filter(x => x.tableName.toUpperCase() !== 'TB_USUARIO')
		.forEach(item => {
			let listAddExpandString = '';
			const componentProperties = widgets
				.filter(x =>
					[ComponentSimpleEnum.Select, ComponentSimpleEnum.Autocomplete].includes(
						x.name as any,
					),
				)
				.find(x => x.properties.name === item.refId)?.properties;

			if (
				componentProperties?.finderData?.propData?.useParentDependency &&
				componentProperties.dependencyList &&
				componentProperties.dependencyList.length > 0
			) {
				listAddExpandString = loadSearchDependencies(componentProperties, widgets, app);
			}

			const mRefId = item.refId.includes('_')
				? backFormatPropertyName(item.refId)
				: item.refId;

			const mTableName = item.tableName.includes('_')
				? dotnetFormatScreenName(item.tableName ?? '')
				: item.tableName;

			listExpandString += `{tableName: '${mTableName}', refId: '${mRefId}', refName: '${backFormatPropertyName(
				item.refName ?? '',
			)}', dependencies: [${listAddExpandString}]},`;
		});

	let propsMap = mComponents.map(x => {
		const findedItem = listExpand.find(y => {
			const findedItemRef = y.refId.includes('_') ? backFormatPropertyName(y.refId) : y.refId;
			return findedItemRef === x.properties.name;
		});

		if (findedItem) {
			const mPropRefName = findedItem.refName.includes('_')
				? backFormatPropertyName(findedItem.refName)
				: findedItem.refName;
			return `{ prop: '${
				x.properties.label ?? x.properties.text
			}', destiny: '${mPropRefName}', type: ${parseDatabaseMaskToDatabaseColumn(
				x.properties?.mask,
			)}}`;
		}

		if (
			x.properties.finderData &&
			x.properties.finderData.propData &&
			x.properties.finderData.propData.value
		) {
			const mPropData = x.properties.finderData;
			const column = app.database?.tables
				?.find(y => y.ref === mPropData.tableRef)
				?.columns?.find(y => y.ref === mPropData.propData.value);
			return `{ prop: '${
				x.properties.label ?? x.properties.text
			}', destiny: '${backFormatPropertyName(
				column?.name ?? '',
			)}', type: ${parseDatabaseMaskToDatabaseColumn(column?.mask)}}`;
		}

		return `{ prop: '${x.properties.label ?? x.properties.text}', destiny: '${
			x.properties.internalName ?? x.properties.name
		}', type: ${parseDatabaseMaskToDatabaseColumn(x.properties?.mask)} }`;
	});

	const databaseTableRef = app.apis
		?.find(x => x.ref === action.api?.targetApi)
		?.endpoints?.find(x => x.ref === action.api?.targetEndpoint)?.databaseTableRef;

	//TODO: esta verificação precisa ser revista quando formos refatorar
	const primarys = app.database?.tables
		?.find(t => t.ref === databaseTableRef)
		?.columns?.filter(c => c.isPrimaryKey || c.autoIncremente);

	const propsPrimary: string[] = [];
	const propsMapPrimary: string[] = [];

	primarys?.forEach((c: IColumn) => {
		propsPrimary.push(`'${c.suggestName ?? c.internalName ?? ''}'`);

		propsMapPrimary.push(
			`{ prop: '${c.suggestName ?? c.internalName}', destiny: '${
				c.internalName ?? c.name
			}', type: ${c?.type} }`,
		);
	});

	if (propsPrimary?.length > 0) {
		props = [...propsPrimary, ...props];
	}

	if (propsMapPrimary?.length > 0) {
		propsMap = [...propsMapPrimary, ...propsMap];
	}

	const code = `
		${getSet[1]}({
			isShow: true,
			properties: [${props.join(', ')}],
			propertiesMap: [${propsMap.join(', ')}],
			onSearch: async (data: any) => {
				${addApiAction(
					action,
					widgets,
					app,
					apiImports,
					fileInstances,
					instanceContext,
					importContext,
					widgetImports,
					true,
				)}
			},
			onSelect: (item: any) => {
				setFormData(item);
				${getSet[1]}({
					isShow: false
				});
			},
			expand: [${listExpandString}]
		});
	`;

	return code;
};

const parseDatabaseMaskToDatabaseColumn = (
	mask: DatabaseMaskEnum | undefined,
): DatabaseColumnEnum => {
	let result: DatabaseColumnEnum = DatabaseColumnEnum.String;

	if (mask !== undefined) {
		switch (mask) {
			case DatabaseMaskEnum.Monetario:
				result = DatabaseColumnEnum.Decimal;
				break;
			case DatabaseMaskEnum.Numero:
				result = DatabaseColumnEnum.Int32;
				break;
			default:
				result = DatabaseColumnEnum.String;
				break;
		}
	}

	return result;
};

const addNextPageAction = (action: IAction, app: IApplication, widgetImports: string[]): string => {
	const pathName = resourceRouteFormatted(app.resources?.find(x => x.ref === action?.targetRef));

	if (!widgetImports.find(x => x.includes('useNavigate')))
		widgetImports.push(`import {useNavigate} from 'react-router-dom';`);
	if (!widgetImports.find(x => x.includes('ROUTER_PATHS')))
		widgetImports.push(`import {ROUTER_PATHS} from 'src/router/router.path';`);

	const functionString = `useNavigate()(\`/\${ROUTER_PATHS.${pathName}}\`);`;

	return functionString;
};

const addApiAction = (
	action: IAction,
	widgets: IComponent[],
	app: IApplication,
	apiImports: string[],
	fileInstances: string[],
	instanceContext: string[],
	importContext: string[],
	widgetImports: string[],
	searchMethod = false,
	resource?: IResource,
): string => {
	if (!action) return '';
	const currentApi = action.api;
	const apiAction = app.apis?.find(x => x.ref === currentApi?.targetApi);
	const apiEndpoint = apiAction?.endpoints?.find(x => x.ref === currentApi?.targetEndpoint);

	const nameRepo = getRepositoryClass(apiAction?.name ?? '');
	const repoFileName = getRepositoryName(apiAction?.name ?? '');
	const instanceName = `_${lowerFirstWord(nameRepo)}`;

	const isUser =
		app.database?.tables?.find(x => x.ref === apiEndpoint?.databaseTableRef)?.isUser ?? false;

	const resultName = 'result' + upperFirstWord(apiEndpoint?.name?.toLocaleLowerCase() ?? '');
	const resultConst =
		apiEndpoint && apiEndpoint.response && apiEndpoint.response.length > 0
			? `const ${resultName} = `
			: '';

	const setDataMethod = resource?.apiData?.find(x => x.targetEndpoint === apiEndpoint?.ref);

	let requestData = '';
	let responseData = '';

	if (currentApi && apiEndpoint) {
		if (currentApi.mapRequest && apiEndpoint.request && apiEndpoint.request.length > 0) {
			requestData = `{${generateReqRespParam(
				resource ?? {},
				app,
				apiEndpoint.request,
				currentApi.mapRequest,
				widgets,
				'request',
				resultName,
			)}
				}`;
		}

		//HOTFIX: correção na definição da parte de autenticação
		//if (!apiEndpoint.isAuth) {
		if (!isUser) {
			if (currentApi.mapResponse && apiEndpoint.response && apiEndpoint.response.length > 0) {
				responseData = generateReqRespParam(
					resource ?? {},
					app,
					apiEndpoint.response,
					currentApi.mapResponse,
					widgets,
					'response',
					resultName,
				);
			}
		} else {
			if (!widgetImports.find(x => x.includes('useNavigate')))
				widgetImports.push(`import {useNavigate} from 'react-router-dom';`);

			let userEnvCode = '';
			app.userConfiguration?.mapping
				?.filter(x => x.isEnvironment)
				.forEach(mapItem => {
					userEnvCode += `${lowerFirstWord(
						mapItem.columnName ?? '',
					)}: (${resultName} as any).result.token.${lowerFirstWord(
						mapItem.columnName ?? '',
					)},\n`;
				});
			app.userConfiguration?.mapCustom?.forEach(mapItem => {
				const lastItem = mapItem.customMapVars[mapItem.customMapVars.length - 1];
				const propName = `${lowerFirstWord(lastItem.table?.label ?? '')}${
					lastItem.column?.label
				}`;
				userEnvCode += `${propName}: (${resultName} as any).result.token.${propName},\n`;
			});
			responseData = `localStorage.setItem('jwt_token', (${resultName} as any).result.token.token);

			setUser({
				${userEnvCode}
			});

			setTimeout(() => {
				useNavigate()('/');
			}, 700);`;
		}
	}

	//TODO: esta verificação precisa ser revista quando formos refatorar
	const argumentsMethod: string[] = [];
	const primarys = app.database?.tables
		?.find(t => t.ref === resource?.databaseTableRef)
		?.columns?.filter(c => c.isPrimaryKey || c.autoIncremente);
	primarys?.forEach((c: IColumn) => {
		let dataType = 'string';

		if (c.type === DatabaseColumnEnum.Boolean) {
			dataType = 'boolean';
		} else if (
			c.type === DatabaseColumnEnum.Byte ||
			c.type === DatabaseColumnEnum.Int16 ||
			c.type === DatabaseColumnEnum.Int32 ||
			c.type === DatabaseColumnEnum.Int64 ||
			c.type === DatabaseColumnEnum.Decimal
		) {
			dataType = 'number';
		} else if (
			c.type === DatabaseColumnEnum.Date ||
			c.type === DatabaseColumnEnum.DateOnly ||
			c.type === DatabaseColumnEnum.DateTime
		) {
			dataType = 'Date';
		}

		argumentsMethod.push(`formData!.${c.internalName}`);
	});

	const loadingCode: string[] = getUseLoading(
		action,
		importContext,
		instanceContext,
		searchMethod,
	);
	const executionCode = `
				${loadingCode[0]}
				${searchMethod ? 'const resultData = ' : resultConst}await ${instanceName}.${apiEndpoint?.name}(${
		searchMethod
			? 'data'
			: apiEndpoint?.method === ApiMethods.DELETE
			? argumentsMethod.length === 1
				? `${argumentsMethod}!`
				: argumentsMethod.join('!, ')
			: requestData
	});
				${setDataMethod ? `set${upperFirstWord(setDataMethod.name ?? '')}(${resultName})` : ''}
				${getUseDefineFail(action)}
				${responseData}
				${getUseModal(action, importContext, instanceContext, 'modalSuccess')}
				${searchMethod ? 'return resultData;' : 'clearPage();'}
			`;
	const confirmModal = getUseConfirmModal(action, executionCode, importContext, instanceContext);

	const functionString = `
				try {
					${confirmModal ?? executionCode}
				} catch (e) {
					${getUseModal(action, importContext, instanceContext, 'modalFail')}
				} finally {
					setIsLoading(false);
				}
			`;

	if (apiImports.filter(x => x.includes(nameRepo)).length === 0)
		apiImports.push(`import {${nameRepo}} from 'src/shared/repositories/${repoFileName}';`);

	if (fileInstances.length === 0 || !fileInstances?.find(x => x.includes(nameRepo)))
		fileInstances.push(`const ${instanceName} = new ${nameRepo}();`);

	return functionString;
};

const getUseConfirmModal = (
	action: IAction,
	executionCode: string,
	importContext: string[],
	useContext: string[],
): string | void => {
	if (action.modal && action.modal.enable) {
		setModalImports(importContext, useContext);

		return `
			setCustomModal({
				isShow: true,
				title: '${action.modal.title}',
				description: '${action.modal.description}',
				onConfirm: async () => {
					try {
						${executionCode}
					} catch {
					} finally {
						setIsLoading(false);
					}
				}
			});
		`;
	}
};

const getUseModal = (
	action: IAction,
	importContext: string[],
	useContext: string[],
	type: 'modalSuccess' | 'modalFail',
): string => {
	if (action.api && action.api[type]?.enable) {
		setModalImports(importContext, useContext);

		return `
			setCustomModal({
				isShow: true,
				title: '${action.api[type]?.title}',
				description: '${action.api[type]?.description}'
			});
		`;
	}

	return '';
};

const generateReqRespParam = (
	resource: IResource,
	application: IApplication,
	param: IApiMap[],
	mapData: IActionApiMap[],
	widgets: IComponent[],
	type: 'request' | 'response',
	resultName: string,
	isFiltered?: boolean,
	paramNoFilter?: IApiMap[],
): string => {
	let codeParam = '';

	const tableRef = application.database?.tables?.find(
		table => table.ref === resource.databaseTableRef,
	);

	const propsExcludedString: string[] = [];
	const propsExcludedStringRequired: IColumn[] = [];

	application.databaseRules?.forEach(x => {
		tableRef?.columns?.forEach(column => {
			if (column.name?.includes(x.findedName ?? '') && column.nullable)
				propsExcludedString.push(backFormatPropertyName(column.name ?? ''));
			else if (column.name?.includes(x.findedName ?? '') && !column.nullable)
				propsExcludedStringRequired.push(column);
		});
	});

	if (!tableRef) {
		application.databaseRules?.forEach(x => {
			const table =
				application.database && application.database.tables
					? application.database.tables[0]
					: undefined;

			table?.columns?.forEach(column => {
				if (column.name?.includes(x.findedName ?? '') && column.nullable)
					propsExcludedString.push(backFormatPropertyName(column.name ?? ''));
				else if (column.name?.includes(x.findedName ?? '') && !column.nullable)
					propsExcludedStringRequired.push(column);
			});
		});
	}

	param
		.filter(x => (isFiltered ? true : !x.parentRef))
		.filter(x => !propsExcludedString.includes(x.name ?? ''))
		.forEach(item => {
			if (
				['string', 'boolean', 'number', 'array', 'file', 'Date'].includes(item?.type ?? '')
			) {
				const map = mapData.find(x => x.targetApiRef === item.ref);
				const targetComp = widgets.find(x => x.ref === map?.targetComponentRef);
				let targetCompName = targetComp?.properties['name'];
				if (!targetCompName) {
					if (widgets.find(x => x.properties['name'] === item.name))
						targetCompName = item.name;
				}

				const targetCompMask = targetComp?.properties['mask'];
				const isBooleanValue = [
					ComponentSimpleEnum.Switch,
					ComponentSimpleEnum.Checkbox,
				].includes(targetComp?.name ?? ('' as any));

				let regexString = '';
				if (
					[
						DatabaseMaskEnum.Cnpj,
						DatabaseMaskEnum.Cpf,
						DatabaseMaskEnum.Cep,
						DatabaseMaskEnum.CpfCnpj,
						DatabaseMaskEnum.Telefone,
						DatabaseMaskEnum.Placa,
					].includes(targetCompMask)
				)
					regexString = `?.replace(/\\D/g,'') as any`;
				if ([DatabaseMaskEnum.Monetario].includes(targetCompMask))
					regexString = `?.substring(3).replaceAll('.', '').replace(',', '.') as any`;
				const complement = regexString ? '' : isBooleanValue ? ' ?? false' : '!';

				if (type === 'request') {
					if (
						propsExcludedStringRequired
							.map(x => backFormatPropertyName(x.name ?? ''))
							.includes(item.name ?? '')
					) {
						const columnRef = propsExcludedStringRequired.find(
							x => backFormatPropertyName(x.name ?? '') === item.name,
						);
						const complement =
							columnRef?.type === DatabaseColumnEnum.Byte ||
							columnRef?.type === DatabaseColumnEnum.Int16 ||
							columnRef?.type === DatabaseColumnEnum.Int32 ||
							columnRef?.type === DatabaseColumnEnum.Int64
								? '2 as any'
								: 'new Date()';
						codeParam += `${item.name}: ${complement},`;
					} else {
						if (item.type === ApiParamTypeEnum.ARRAY) {
							codeParam += `${item.name}: formData!.${item.componentRef?.replace(
								'grid',
								'',
							)}${complement},`;
						} else {
							if (targetCompName === undefined) {
								//TODO: esta verificação precisa ser revista quando formos refatorar
								const primarys = application.database?.tables
									?.find(t => t.ref === resource.databaseTableRef)
									?.columns?.filter(c => c.isPrimaryKey || c.autoIncremente);
								primarys?.forEach((c: IColumn) => {
									codeParam += `${item.name}: formData!.${
										c.internalName ?? '' ?? ''
									}${complement}${regexString},`;
								});
							} else {
								codeParam += `${item.name}: formData!.${
									targetCompName ?? 'Id'
								}${complement}${regexString},`;
							}
						}
					}
				} else
					codeParam += `set${upperFirstWord(targetCompName)}(${resultName}.${
						item.name
					});`;
			} else if (item.type === 'object') {
				const codeSubParam = generateReqRespParam(
					resource,
					application,
					(paramNoFilter ?? param).filter(x => x.parentRef === item.ref),
					mapData,
					widgets,
					type,
					resultName,
					true,
					paramNoFilter ?? param,
				);

				codeParam += `${item.name}: {${codeSubParam}},`;
			}
		});

	return codeParam;
};

const setModalImports = (importContext: string[], useContext: string[]) => {
	const importModal = `import CustomModalContext from 'src/context/CustomModalContext';`;
	const instanceModal = `const [, setCustomModal] = useContext(CustomModalContext);`;

	if (!importContext.includes(importModal)) importContext.push(importModal);
	if (!useContext.includes(instanceModal)) useContext.push(instanceModal);
};

const getUseLoading = (
	action: IAction,
	importContext: string[],
	useContext: string[],
	searchMethod = false,
): string[] => {
	if (!action.showLoading && !searchMethod) return ['', ''];
	const importLoad = `import LoadingContext from 'src/context/LoadingContext';`;
	const instanceLoad = `const [, setIsLoading] = useContext(LoadingContext);`;

	if (!importContext.includes(importLoad)) importContext.push(importLoad);
	if (!useContext.includes(instanceLoad)) useContext.push(instanceLoad);

	return ['setIsLoading(true);', 'setIsLoading(false);'];
};

const getUseSearch = (action: IAction, importContext: string[], useContext: string[]): string[] => {
	const importLoad = `import SearchModalContext from 'src/context/SearchModalContext';`;
	const instanceLoad = `const [, setSearchModal] = useContext(SearchModalContext);`;

	if (!importContext.includes(importLoad)) importContext.push(importLoad);
	if (!useContext.includes(instanceLoad)) useContext.push(instanceLoad);

	return ['searchModal', 'setSearchModal'];
};

const getUseDefineFail = (action: IAction): string => {
	if (
		action &&
		action.api &&
		action.api.defineFail &&
		action.api.defineFail.prop &&
		action.api.defineFail.value &&
		action.api.defineFail.value.trim() != ''
	) {
		const defineFail = action.api.defineFail;
		return `
			if (result.${defineFail.prop}?.toString() ${defineFail.condition} '${defineFail.value}') throw new Error();
		`;
	}

	return '';
};

const getInvokeMethod = (type: ComponentSimpleEnum): string => {
	if ([ComponentSimpleEnum.Button, ComponentSimpleEnum.IconButton].includes(type))
		return 'onClick';
	return '';
};

const addSetValue = (action: IAction, apiData: IApiData | undefined): string => {
	if (!apiData) return '';
	let code = '';

	action.data?.forEach((item: any) => {
		const setMethod = `set${upperFirstWord(item.componentName)}`;
		code += `${setMethod}(${apiData.name}?.${item.property?.split('.')?.join('?.')});\n`;
	});
	return code;
};
