import {faHome, faChevronRight, faLockOpen, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {ITemplateMenu, IApplication} from 'src/@types/app';
import * as S from './styles';

type Props = {
	isOpen: boolean;
	menuData: ITemplateMenu[];
	app: IApplication;
};

const MenuBar = ({isOpen, menuData, app}: Props) => {
	const [menuItens, setMenuItens] = useState<ITemplateMenu[]>([]);

	useEffect(() => {
		setMenuItens(menuData);
	}, [menuData]);

	const openMenu = (item: ITemplateMenu) => {
		const newData = [...menuItens].map(x => {
			if (x.ref == item.ref && item.type == 'folder') x.isOpen = !x.isOpen;
			return x;
		});

		setMenuItens(newData);
	};

	const getSubItens = (item: ITemplateMenu, itens: ITemplateMenu[]): ITemplateMenu[] => {
		const listItens: ITemplateMenu[] = [];
		itens.forEach(x => {
			if (x.path) {
				const folderOrigin =
					item.path && item.path != ''
						? `${item.path.substring(1)}/${item.regularName}`
						: `${item.path}${item.regularName}`;
				const dest = x.path.substring(1);
				if (folderOrigin === dest) listItens.push(x);
			}
		});

		return listItens;
	};

	const renderSubMenus = (item: ITemplateMenu, index: number, subItens: ITemplateMenu[]) => {
		return (
			<div key={index}>
				<S.SubItemContent onClick={() => openMenu(item)}>
					<S.SubItem>{item.name ?? ''}</S.SubItem>
					<S.Arrow>
						{(function () {
							if (item.type === 'folder')
								return (
									<FontAwesomeIcon
										icon={item.isOpen ? faChevronDown : faChevronRight}
									/>
								);
						})()}
					</S.Arrow>
				</S.SubItemContent>
				{isOpen && item.isOpen ? (
					subItens.map((subItem, indexSub) =>
						renderSubMenus(subItem, indexSub, getSubItens(item, menuItens)),
					)
				) : (
					<></>
				)}
			</div>
		);
	};

	const renderMenus = (item: ITemplateMenu, index: number, subItens: ITemplateMenu[]) => {
		return (
			<div key={index}>
				<S.ItemContent key={index} onClick={() => openMenu(item)}>
					<S.Icon isOpen={isOpen}>
						<FontAwesomeIcon
							icon={item.icon?.icon ?? faHome}
							color={app?.layout?.templateConfig?.Layout?.icon}
						/>
					</S.Icon>
					{(function () {
						if (isOpen) {
							return (
								<>
									<S.Item>{item.name}</S.Item>
									<S.Arrow>
										{(function () {
											if (item.type === 'folder')
												return (
													<FontAwesomeIcon
														icon={
															item.isOpen
																? faChevronDown
																: faChevronRight
														}
													/>
												);
										})()}
									</S.Arrow>
								</>
							);
						}
					})()}
				</S.ItemContent>
				{isOpen && item.isOpen ? (
					subItens.map((subItem, indexSub) =>
						renderSubMenus(subItem, indexSub, getSubItens(subItem, menuItens)),
					)
				) : (
					<></>
				)}
			</div>
		);
	};

	return (
		<S.Container isOpen={isOpen}>
			<S.ContainerMenu>
				{menuItens
					.filter(x => x.path == '')
					.map((item, index) => renderMenus(item, index, getSubItens(item, menuItens)))}
			</S.ContainerMenu>

			<S.ContainerMenuLogout>
				<S.ItemContentLogout>
					<S.Icon isOpen={isOpen}>
						<FontAwesomeIcon
							icon={faLockOpen}
							color={app?.layout?.templateConfig?.Layout?.icon}
						/>
					</S.Icon>
					{(function () {
						if (isOpen) {
							return (
								<>
									<S.Item>Logout</S.Item>
									<S.Arrow>
										<FontAwesomeIcon icon={faChevronRight} />
									</S.Arrow>
								</>
							);
						}
					})()}
				</S.ItemContentLogout>
			</S.ContainerMenuLogout>
		</S.Container>
	);
};

export default MenuBar;
