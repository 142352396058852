/* eslint-disable @typescript-eslint/no-explicit-any */
import DefaultLayout from './default';
import NiftyLayout from './nifty';
import {TemplateEnum} from './template-enum';

export const getTemplateComponent = (type: TemplateEnum) => {
	switch (type) {
		case TemplateEnum.Nifty:
			return NiftyLayout;
		default:
			return DefaultLayout;
	}
};

export const getTemplateConfig = (type?: TemplateEnum) => {
	switch (type) {
		case TemplateEnum.Nifty:
			return niftyConfig;
		default:
			return {};
	}
};

const niftyConfig: any = {
	Input: {
		inputType: 'light',
	},
	InputDate: {
		inputType: 'light',
		iconPosition: 'right',
	},
	InputTag: {
		inputType: 'light',
	},
	Select: {
		inputType: 'light',
	},
	Autocomplete: {
		inputType: 'light',
	},
	Box: {
		background: '#fff',
		boxShadow: '0 1rem 1rem -0.75rem rgb(105, 96, 215, 0.18)',
	},
	Checkbox: {
		inputType: 'light',
	},
	Button: {
		textColor: '#fff',
		background: '#238636',
	},
	Layout: {
		backTop: '#1bb355',
		backBottom: '#edf1f6',
		title: '#0e0e0e',
		icon: '#ec4401',
	},
};
