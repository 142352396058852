import prettier from 'prettier/standalone';
import {IApplication} from 'src/@types/app';
import {PrettierConfig} from 'src/shared/engine/common/prettier-config';
import {resourceRouteFormatted} from 'src/shared/engine/route-generator';

export const createMainComponent = (application: IApplication): string => {
	let menus = '';

	application?.resources
		?.filter(item => item.menu?.use)
		.forEach(item => {
			const pathName = resourceRouteFormatted(item);

			menus += `
        {
          ref: uuidV4().toString(),
          name: '${item.menu?.name ?? "''"}',
          path: '${item.path ?? ''}',
          type: 'component',
          route: ROUTER_PATHS.${pathName},
          regularName: '${item.name}',
        },
      `;
		});
	application?.folders
		?.filter(item => item.menu?.use)
		.forEach(item => {
			menus += `
        {
          ref: uuidV4().toString(),
          name: '${item.menu?.name ?? ''}',
          path: '${item.path ?? ''}',
          type: 'folder',
          regularName: '${item.name}',
        },
      `;
		});

	let code = `
    import React, {ReactNode, useState} from 'react';
    import {ITemplateMenu} from './models';
    import MenuBar from './components/MenuBar';
    import TopBar from './components/TopBar';
    import * as S from './styles';
    import {ROUTER_PATHS} from 'src/router/router.path';
    import {v4 as uuidV4} from 'uuid';

    type Props = {
      children: ReactNode;
      pageName?: string;
    };

    const NiftyLayout = ({children, pageName}: Props) => {
      const [isOpenMenu, setIsOpenMenu] = useState(false);
      const [showLogout, setShowLogout] = useState(false);

      const templateMenu: ITemplateMenu[] = [${menus}];

      return (
        <S.Main>
          <S.BackGround>
            <S.BackGroundTop />
            <S.BackGroundBottom />
          </S.BackGround>
          <S.Container>
            <TopBar isOpenMenu={isOpenMenu} setIsOpenMenu={setIsOpenMenu} />
            <S.Body>
              <MenuBar isOpen={isOpenMenu} menuData={templateMenu} />
              <S.FormArea isOpen={isOpenMenu}>
                <S.ContentTop>
                  <S.Title>{pageName ?? ''}</S.Title>
                </S.ContentTop>
                {children}
              </S.FormArea>
            </S.Body>
          </S.Container>
        </S.Main>
      );
    };

    export default NiftyLayout;
  `;

	code = prettier.format(code, PrettierConfig);

	return code;
};

export const createTopBarComponent = (imgBase64: string): string => {
	const logo = imgBase64
		? `<img
  style={{display: 'block', width: '43px', height: '43px', borderRadius: '43px', marginLeft: '2px'}}
  id="base64image"
  src="${imgBase64}"
/>`
		: 'Logo';
	let code = `
    import React from 'react';
    import * as S from './styles';

    type Props = {
      isOpenMenu: boolean;
      setIsOpenMenu: (val: boolean) => void;
    };

    const TopBar = ({isOpenMenu, setIsOpenMenu}: Props) => {
      return (
        <S.Container>
          <S.Logo isOpen={isOpenMenu}>
            ${logo}
          </S.Logo>
          <S.MenuAction isOpen={isOpenMenu}>
            <S.MenuActionBtn onClick={() => setIsOpenMenu(!isOpenMenu)}>
              <i className="fa fa-bars"></i>
            </S.MenuActionBtn>
          </S.MenuAction>
          <S.IconsContent>
            <S.MenuActionBtn>
              <i className="fa fa-bars"></i>
            </S.MenuActionBtn>
            <S.MenuActionBtn>
              <i className="fa fa-bell"></i>
            </S.MenuActionBtn>
            <S.MenuActionBtn>
              <i className="fa fa-user"></i>
            </S.MenuActionBtn>
            <S.MenuActionBtn>
              <i className="fa fa-ellipsis-vertical"></i>
            </S.MenuActionBtn>
          </S.IconsContent>
        </S.Container>
      );
    };

    export default TopBar;
  `;

	code = prettier.format(code, PrettierConfig);

	return code;
};

export const createMenuBarComponent = (iconColor: string): string => {
	let code =
		`
    import {faHome, faChevronRight, faLockOpen, faChevronDown} from '@fortawesome/free-solid-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
    import React, {useEffect, useState} from 'react';
    import {ITemplateMenu} from 'src/template/models';
    import * as S from './styles';
    import {useNavigate} from 'react-router-dom';
    import {Modal} from 'src/components/Complex';
    import {Button, ModalTitle, Wrapper} from 'src/components/UI';

    type Props = {
      isOpen: boolean;
      menuData: ITemplateMenu[];
    };

    const MenuBar = ({isOpen, menuData}: Props) => {
      const navigate = useNavigate();
      const [menuItens, setMenuItens] = useState<ITemplateMenu[]>([]);
      const [showLogout, setShowLogout] = useState(false);

      useEffect(() => {
        setMenuItens(menuData);
      }, [menuData]);

      const openMenu = (item: ITemplateMenu) => {
        const newData = [...menuItens].map(x => {
          if (x.ref == item.ref && item.type == 'folder') x.isOpen = !x.isOpen;
          return x;
        });

        setMenuItens(newData);
      };\n` +
		'const getSubItens = (item: ITemplateMenu, itens: ITemplateMenu[]): ITemplateMenu[] => {' +
		'    const listItens: ITemplateMenu[] = [];' +
		'    itens.forEach(x => {' +
		'        if (x.path) {' +
		'            const folderOrigin =' +
		"                item.path && item.path != ''" +
		'                    ? `${item.path.substring(1)}/${item.regularName}`' +
		'                    : `${item.path}${item.regularName}`;' +
		'            const dest = x.path.substring(1);' +
		'            if (folderOrigin === dest) listItens.push(x);' +
		'        }' +
		'    });' +
		'' +
		'    return listItens;' +
		'};' +
		`

      const renderSubMenus = (item: ITemplateMenu, index: number, subItens: ITemplateMenu[]) => {
        return (
          <div key={index}>
            <S.SubItemContent onClick={() =>
              item.type === 'folder' ? openMenu(item) : navigate(item.route ?? '')
            }>
              <S.SubItem>{item.name ?? ''}</S.SubItem>
              <S.Arrow>
                {(function () {
                  if (item.type === 'folder')
                    return (
                      <FontAwesomeIcon
                        icon={item.isOpen ? faChevronDown : faChevronRight}
                      />
                    );
                })()}
              </S.Arrow>
            </S.SubItemContent>
            {isOpen && item.isOpen ? (
              subItens.map((subItem, indexSub) =>
                renderSubMenus(subItem, indexSub, getSubItens(subItem, menuItens)),
              )
            ) : (
              <></>
            )}
          </div>
        );
      };

      const renderMenus = (item: ITemplateMenu, index: number, subItens: ITemplateMenu[]) => {
        return (
          <div key={index}>
            <S.ItemContent key={index} onClick={() =>
                item.type === 'folder' ? openMenu(item) : navigate(item.route ?? '')
              }>
              <S.Icon isOpen={isOpen}>
                <FontAwesomeIcon icon={faHome} color='${iconColor}' />
              </S.Icon>
              {(function () {
                if (isOpen) {
                  return (
                    <>
                      <S.Item>{item.name}</S.Item>
                      <S.Arrow>
                        {(function () {
                          if (item.type === 'folder')
                            return (
                              <FontAwesomeIcon
                                icon={
                                  item.isOpen
                                    ? faChevronDown
                                    : faChevronRight
                                }
                              />
                            );
                        })()}
                      </S.Arrow>
                    </>
                  );
                }
              })()}
            </S.ItemContent>
            {isOpen && item.isOpen ? (
              subItens.map((subItem, indexSub) =>
                renderSubMenus(subItem, indexSub, getSubItens(subItem, menuItens)),
              )
            ) : (
              <></>
            )}
          </div>
        );
      };

      const logoutModal = () => {
        return (
          <Modal isOpen={showLogout} onClose={() => setShowLogout(false)} width="22%">
            <>
              <ModalTitle color="black">Logout</ModalTitle>
              <Wrapper margin="0 10px">Deseja fazer o logout?</Wrapper>
              <Wrapper margin="20px 0 0 0" justifyContent="end">
                <Button
                  onClick={() => setShowLogout(false)}
                  text="Não"
                  fill="auto"
                  themeStyle="danger"
                />
                <Button
                  onClick={() => {
                    localStorage.setItem('jwt_token', '');
                    navigate('/');
                  }}
                  text="Sim"
                  fill="auto"
                />
              </Wrapper>
            </>
          </Modal>
        );
      };

      return (
        <>
          {logoutModal()}
          <S.Container isOpen={isOpen}>
            <S.ContainerMenu>
              {menuItens
                .filter(x => x.path == '')
                .map((item, index) => renderMenus(item, index, getSubItens(item, menuItens)))}
            </S.ContainerMenu>

            <S.ContainerMenuLogout>
              <S.ItemContentLogout onClick={() => setShowLogout(true)}>
                <S.Icon isOpen={isOpen}>
                  <FontAwesomeIcon icon={faLockOpen} color='${iconColor}' />
                </S.Icon>
                {(function () {
                  if (isOpen) {
                    return (
                      <>
                        <S.Item>Logout</S.Item>
                        <S.Arrow>
                          <FontAwesomeIcon icon={faChevronRight} />
                        </S.Arrow>
                      </>
                    );
                  }
                })()}
              </S.ItemContentLogout>
            </S.ContainerMenuLogout>
          </S.Container>
        </>
      );
    };

    export default MenuBar;

  `;

	code = prettier.format(code, PrettierConfig);

	return code;
};
