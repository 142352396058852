import React, {useEffect, useState} from 'react';
import {IApi, IApiEndpoint, IOption} from 'src/@types/app';
import {ApiBodyTypeList} from 'src/helpers/select-data/apiBodyTypeList';
import {ApiMethodsList} from 'src/helpers/select-data/apiMethodsList';
import {v4 as uuidV4} from 'uuid';
import AddApiEndpointRequest from '../AddApiEndpointRequest';
import AddApiEndpointResponse from '../AddApiEndpointResponse';
import {
	IconButton,
	Wrapper,
	Text,
	WidthBox,
	Switch,
	Button,
	SizedBox,
	Line,
	ModalTitle,
} from 'src/shared/components/UI';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import * as S from '../../styles';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	apiData: IApi;
	save: (api: IApi) => void;
	back: () => void;
};

interface CellType {
	row: IApiEndpoint;
}

const AddApiEndpoint = ({apiData, save, back}: Props) => {
	const [name, setName] = useState('');
	const [method, setMethod] = useState<IOption>();
	const [bodyType, setBodyType] = useState<IOption>();
	const [route, setRoute] = useState('');
	const [selectedRequest, setSelectedRequest] = useState<IApiEndpoint>();
	const [selectedResponse, setSelectedResponse] = useState<IApiEndpoint>();
	const [editItem, setEditItem] = useState<IApiEndpoint>();
	const [isAuth, setIsAuth] = useState(false);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IApiEndpoint | null>(null);

	useEffect(() => {
		setMethod(ApiMethodsList[0]);
		setBodyType(ApiBodyTypeList[0]);
	}, []);

	const isValid = () => name.trim() != '' && route.trim() != '' && method && bodyType;

	const saveEndpoint = () => {
		if (isValid()) {
			const newApi: IApi = {...apiData, endpoints: [...(apiData.endpoints ?? [])]};

			if (editItem) {
				newApi.endpoints = newApi.endpoints?.map(item => {
					if (item.ref === editItem.ref) {
						item.name = name;
						item.method = method?.value;
						item.bodyType = bodyType?.value;
						item.route = route;
						item.isAuth = isAuth;
					}
					return item;
				});
			} else {
				newApi.endpoints?.push({
					ref: uuidV4().toString(),
					name: name,
					method: method?.value,
					bodyType: bodyType?.value,
					route: route,
					isAuth: isAuth,
				});
			}

			save(newApi);
			clear();
		}
	};

	const saveConfig = (data: IApiEndpoint) => {
		const newApi: IApi = {...apiData, endpoints: [...(apiData.endpoints ?? [])]};
		newApi.endpoints = newApi.endpoints?.map(x => {
			if (x.ref === data.ref) return data;
			return x;
		});
		save(newApi);
		if (selectedRequest) setSelectedRequest(data);
		else if (selectedResponse) setSelectedResponse(data);
	};

	const removeEndpoint = (item: IApiEndpoint) => {
		const newApi: IApi = {...apiData, endpoints: [...(apiData.endpoints ?? [])]};
		newApi.endpoints = newApi.endpoints?.filter(x => x.ref != item.ref);
		save(newApi);
	};

	const openDeleteModal = (item: IApiEndpoint) => {
		setItemToDelete(item);
		setIsOpenDelete(true);
	};

	const onRemove = () => {
		if (itemToDelete) {
			removeEndpoint(itemToDelete);
			setItemToDelete(null); // Limpa o item após a exclusão
			setIsOpenDelete(false);
		}
	};

	const clear = () => {
		setName('');
		setRoute('');
		setMethod(ApiMethodsList[0]);
		setBodyType(ApiBodyTypeList[0]);
		setEditItem(undefined);
		setIsAuth(false);
	};

	const edit = (endpoint: IApiEndpoint) => {
		setEditItem(endpoint);
		setName(endpoint.name);
		setMethod(ApiMethodsList.find(x => x.value === endpoint.method));
		setBodyType(ApiBodyTypeList.find(x => x.value === endpoint.bodyType));
		setRoute(endpoint.route);
		setIsAuth(endpoint.isAuth ?? false);
	};

	if (selectedRequest) {
		return (
			<AddApiEndpointRequest
				endpointData={selectedRequest}
				save={saveConfig}
				back={() => setSelectedRequest(undefined)}
			/>
		);
	}

	if (selectedResponse) {
		return (
			<AddApiEndpointResponse
				endpointData={selectedResponse}
				save={saveConfig}
				back={() => setSelectedResponse(undefined)}
			/>
		);
	}

	return (
		<>
			<S.Content>
				<Wrapper>
					<IconButton
						icon={faChevronLeft}
						color={'#000'}
						useBorder={true}
						onClick={back}
					/>
					<Text
						text="Configuração de endpoints"
						fontSize="18px"
						color={'#000'}
						fontWeight="600"
					/>
				</Wrapper>

				<SizedBox height="20px" />
				<S.Card>
					<Wrapper>
						<SystemInput
							width="60%"
							value={name}
							onChange={setName}
							label="Nome do metodo"
							onPressEnter={saveEndpoint}
							mask="Sem espaço"
						/>
						<SystemSelect
							width="40%"
							value={method}
							options={ApiMethodsList}
							onChange={setMethod}
							label="Tipo"
						/>
					</Wrapper>
					<Wrapper margin="20px 0 10px 0">
						<SystemSelect
							width="25%"
							value={bodyType}
							options={ApiBodyTypeList}
							onChange={setBodyType}
							label="Body"
						/>
						<SystemInput
							width="60%"
							value={route}
							onChange={setRoute}
							label="Rota"
							mask="Sem espaço"
							onPressEnter={saveEndpoint}
						/>
						<WidthBox width="15%" margin="20px 0 0 0">
							<Switch
								isOn={isAuth}
								handleToggle={() => setIsAuth(!isAuth)}
								text="Autenticação"
								textColor={'#000'}
							/>
						</WidthBox>
					</Wrapper>
					<Wrapper margin="25px 0 0 0" justifyContent="end">
						<Button
							text={editItem ? 'Editar' : 'Adicionar'}
							onClick={saveEndpoint}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />
					<SystemDataGrid
						rowsData={apiData.endpoints ?? []}
						columns={[
							{
								flex: 0.05,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => setSelectedRequest(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar Request" />
												</MenuItem>
												<MenuItem
													onClick={() => setSelectedResponse(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar Response" />
												</MenuItem>
												<MenuItem
													onClick={() => edit(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => openDeleteModal(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.3,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.name,
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'method',
								headerName: `Método`,
								renderCell: ({row}: CellType) => row.method,
							},
							{
								flex: 0.2,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'route',
								headerName: `Rota`,
								renderCell: ({row}: CellType) => row.route,
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'bodyType',
								headerName: `Body`,
								renderCell: ({row}: CellType) => row.bodyType,
							},
						]}
					/>
				</S.Card>

				<SizedBox height="20px" />
			</S.Content>

			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar Endpoint?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este endpoint? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

export default AddApiEndpoint;
