export default (email: string): string => {
	if (!email) return '';

	// Remove espaços em branco antes e depois da string
	email = email.trim();

	// Remove caracteres inválidos (somente permite letras, números, '@', '.', '-', e '_')
	email = email.replace(/[^\w@.-]/g, '');

	// Garante que o email ainda tenha um '@' e pelo menos um domínio após ele
	/*
	const atIndex = email.indexOf('@');
	if (atIndex === -1 || atIndex === email.length - 1) {
		return ''; // Retorna vazio se não houver um "@" ou se estiver no final
	}
	*/

	return email?.toLowerCase();
};
