/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {AddBarItem} from 'src/shared/components/System/Layout/ui/AddBarItem';
import * as S from './styles';
import {Button, ModalTitle, Switch, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {ApplicationContext, LoadingContext} from 'src/context';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {engineCreate} from 'src/shared/engine/generate';
import {cqrsdddGenerator} from 'src/shared/engine-back/core/dotnet/cqrs-ddd/cqrs-ddd-generator';
import {GenerateApi} from 'src/shared/repositories/generate-api';
import AddFolder from '../AddFolder';
import AddResource from '../AddResource';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';
import {GenerateScreen} from './GenerateScreen';
import {Modal} from 'src/shared/components/Complex';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {IOption} from 'src/@types/app';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';
import FeatherIcon from 'feather-icons-react';

export const AddBarRender = () => {
	const [, setLoading] = useContext(LoadingContext);
	const [application] = useContext(ApplicationContext);

	const [showAddResource, setShowAddResource] = useState(false);
	const [showAddFolder, setShowAddFolder] = useState(false);
	const [showGenerateScreen, setShowGenerateScreen] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [envs, setEnvs] = useState<IOption[]>([]);
	const [env, setEnv] = useState('');
	const [generateFront, setGenerateFront] = useState(true);
	const [generateBack, setGenerateBack] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const [existsRoot, setExistsRoot] = useState(false);
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);

	useEffect(() => {
		setEnvs([
			{value: undefined, label: 'Selecione...'},
			...(application.environments?.map(item => ({
				label: item.name as string,
				value: item.ref as string,
			})) ?? []),
		]);
	}, [application]);

	useEffect(() => {
		// Verifica se o ambiente está preenchido e pelo menos uma das opções (Front ou Back) está marcada
		if (env && (generateFront || generateBack)) {
			if (generateFront) {
				setExistsRoot(application?.resources?.some(x => x.isRoot === true) ?? false);
			}

			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}, [env, generateFront, generateBack]);

	const navigate = useNavigate();

	const resourceApi = new ResourceApi();

	const generateCode = async () => {
		try {
			if (generateFront && existsRoot === false) {
				setIsOpenErrorModal(true);
				return;
			}

			setLoading(true);
			const resources = await resourceApi.getAll(application.id!);
			if (!resources) return;

			try {
				const frontResources = await engineCreate(application, resources, env);
				const backResources = await cqrsdddGenerator(
					{
						...application,
						resources: resources,
					},
					env,
				);

				await new GenerateApi().create({
					front: frontResources,
					back: backResources,
					projectId: application.id ?? 0,
					generateBack: generateBack,
					generateFront: generateFront,
					isBuild: false,
				});
			} catch (error) {
				console.log(error);
			}
		} catch (e) {
		} finally {
			setLoading(false);
			setIsOpenModal(false);
		}
	};

	return (
		<>
			<AddBarItem
				icon="folder-plus"
				text="Adicionar pasta"
				onClick={() => setShowAddFolder(true)}
			/>
			<AddBarItem icon="copy" text="Criar recurso" onClick={() => setShowAddResource(true)} />
			<AddBarItem
				icon="database"
				text="Criar modelo de dados"
				onClick={() => navigate(ROUTER_PATHS.DATABASE)}
			/>
			<AddBarItem
				icon="map"
				text="Visualizar diagrama"
				onClick={() => navigate(ROUTER_PATHS.DIAGRAM)}
			/>
			<AddBarItem
				icon="trending-up"
				text="Visualizar estatísticas"
				onClick={() => console.log}
			/>

			<S.ContentLine>
				<S.Line />
			</S.ContentLine>

			<S.ContentBtn>
				<Button
					onClick={() => setShowGenerateScreen(true)}
					text="Gerar telas"
					leftIcon2="smartphone"
					background={applicationTheme.brand[100]}
					fill="auto"
					textColor={applicationTheme.brand[700]}
				/>

				<Button
					onClick={() => setIsOpenModal(true)}
					text="Gerar código"
					leftIcon2="code"
					background={applicationTheme.brand[100]}
					fill="auto"
					textColor={applicationTheme.brand[700]}
				/>
			</S.ContentBtn>

			<AddFolder isOpen={showAddFolder} onClose={() => setShowAddFolder(false)} />
			<AddResource isOpen={showAddResource} onClose={() => setShowAddResource(false)} />
			<GenerateScreen
				isOpen={showGenerateScreen}
				onClose={() => setShowGenerateScreen(false)}
			/>

			<Modal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}>
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={() => setIsOpenModal(false)}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Gerar código" />

					<Wrapper margin="0 0 14px 0">
						<SystemSelect
							width="100%"
							value={envs.find(x => x.value == env)}
							onChange={val => setEnv(val.value)}
							label="Ambiente"
							options={envs}
						/>
					</Wrapper>
					<Wrapper margin="0 0 14px 0">
						<Switch
							text="Gerar Front"
							handleToggle={() => setGenerateFront(!generateFront)}
							isOn={generateFront}
						/>
						<Switch
							text="Gerar Back"
							handleToggle={() => setGenerateBack(!generateBack)}
							isOn={generateBack}
						/>
					</Wrapper>

					<Wrapper justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
							<Button
								text="Gerar"
								leftIcon2="settings"
								onClick={generateCode}
								fill="auto"
								background={applicationTheme.brand[600]}
								disabled={!isValid} // Habilita/desabilita o botão de acordo com isValid
							/>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={() => setIsOpenModal(false)}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>

			<Modal
				isOpen={isOpenErrorModal}
				onClose={() => setIsOpenErrorModal(false)}
				width="auto">
				<>
					<ModalTitle
						title="Parametrização"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-triangle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Necessário definir uma página inicial antes de
						<br />
						realizar a geração do código, verifique.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="center">
						<Button
							text="OK"
							onClick={() => setIsOpenErrorModal(false)}
							fill="full"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};
