import {IApi, IResourceApi, IApiEndpoint, IApiMap, IApplication} from 'src/@types/app';
import {ApiBodyType, ApiMethods} from 'src/@types/enums';
import {lowerFirstWord, upperFirstWord} from 'src/helpers/methods/text-methods';
import prettier from 'prettier/standalone';
import {PrettierConfig} from './common/prettier-config';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {formatScreenName} from 'src/helpers/methods/database-to-screen';

export const repositoryGenerator = (app: IApplication, envRef: string): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];
	let apis = app.apis?.filter(api => api.envRef === envRef);
	if (apis?.length == 0) apis = app.apis;
	resourceApi.push(generateBase(apis ?? []));
	generateEndpoints(apis ?? []).forEach(data => resourceApi.push(data));
	generateInterfaces(apis ?? [], app).forEach(data => resourceApi.push(data));

	return resourceApi;
};

const generateBase = (apis: IApi[]): IResourceApi => {
	let code = `import axios from 'axios';\n`;

	apis.forEach(api => {
		code += `
			export const ${getBaseName(api)} = axios.create({
				baseURL: '${api.baseUrl}',
				headers: {
					'Access-Control-Allow-Headers': 'X-Requested-With, privatekey',
					Authorization: \`Bearer: \${localStorage.getItem('jwt_token')}\`,
				},
			});`;
	});

	code = prettier.format(code, PrettierConfig);

	return {
		code,
		name: `base.ts`,
		path: 'src/shared/repositories/',
	};
};

const generateEndpoints = (apis: IApi[]): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];

	apis.forEach(api => {
		let code = repositoryTemplate(api);
		const imports: string[] = [];
		let endpointsCode = ``;
		let endMethod = '';
		let endRoute = '';

		api.endpoints?.forEach(endpoint => {
			const interfaceName = getInterfaceName(api, endpoint);
			const keys = splitKey(endpoint.route);
			let typeReturn = '';

			if (
				hasRequestParam(endpoint) &&
				endpoint.method != ApiMethods.DELETE &&
				!endpoint.name.toLocaleLowerCase().includes('odata')
			)
				imports.push(interfaceName);

			if (endpoint.response && endpoint.response.length) {
				imports.push(`${interfaceName}Result`);
				typeReturn = `${interfaceName}Result`;
			} else {
				typeReturn = 'void';
			}

			if (endpoint.method == ApiMethods.DELETE) {
				if (hasRequestParam(endpoint)) {
					const queryParams =
						endpoint.request?.map(x => `${lowerFirstWord(x.name!)}: ${x.type}`) ?? [];

					endpointsCode += `
					async ${endpoint.name}(${queryParams.join(', ')}): Promise<void> {
						{ENDPOINT_DATA}
					}
				`;
				}
			} else if (
				endpoint.method === ApiMethods.GET &&
				endpoint.name.toLocaleLowerCase().includes('odata')
			) {
				typeReturn = 'any[]';
				endpointsCode += `
					async ${endpoint.name}(filter: any): Promise<${typeReturn}> {
						{ENDPOINT_DATA}
					}
				`;
			} else if (hasRequestParam(endpoint)) {
				endpointsCode += `
					async ${endpoint.name}(data: ${interfaceName}): Promise<${typeReturn}> {
						{ENDPOINT_DATA}
					}
				`;
			} else {
				endpointsCode += `
					async ${endpoint.name}(): Promise<${typeReturn}> {
						{ENDPOINT_DATA}
					}
				`;
			}

			if (keys && keys.length > 0) {
				keys.forEach(key => {
					endRoute = endpoint.route.replace(`{${key}}`, `\${data.${key}}`);
				});
			} else {
				endRoute = endpoint.route;
			}

			if (endpoint.name.toLocaleLowerCase().includes('odata')) {
				endMethod = `(\`${endRoute}/\${filter}\`)`;
			} else if (endpoint.method == ApiMethods.DELETE) {
				const queryParams =
					endpoint.request?.map(x => '${' + `${lowerFirstWord(x.name!)}` + '}') ?? [];

				endMethod = `(\`${endRoute}/${queryParams.join('/')}\`)`;
			} else if (
				endpoint.method != ApiMethods.GET &&
				endpoint.bodyType === ApiBodyType.JSON
			) {
				endMethod = `('${endRoute}', data)`;
			} else if (endpoint.bodyType === ApiBodyType.QUERY) {
				const queryParams = endpoint.request?.map(x => `${x.name}: data.${x.name},`) ?? [];
				endMethod = `('${endRoute}', {params: {${queryParams.join('')}}})`;
			} else if (endpoint.bodyType === ApiBodyType.URL) {
				endMethod = `(\`${endRoute}\`)`;
			}

			const methodCode = constructMethod(typeReturn, api, endpoint, endMethod);
			endpointsCode = endpointsCode.replace('{ENDPOINT_DATA}', methodCode);
		});

		code = code.replace('{CODE_DATA}', endpointsCode);
		code = code.replace('{IMPORTS_DATA}', imports.join(', '));

		code = prettier.format(code, PrettierConfig);

		resourceApi.push({
			code: code,
			name: `${getRepositoryName(api.name ?? '')}.ts`,
			path: 'src/shared/repositories/',
		});
	});

	return resourceApi;
};

const generateInterfaceProps = (
	app: IApplication,
	mapData?: IApiMap[],
	enableParent = false,
	mapNoFilter?: IApiMap[],
): string => {
	if (!mapData || mapData.length === 0) return '';
	const propsExcludedString: string[] =
		app.databaseRules?.map(x => backFormatPropertyName(x.findedName ?? '')) ?? [];

	let propsCode = '';
	mapData.forEach(map => {
		let isExcluded = '';
		if (propsExcludedString.find(x => map?.name?.includes(x))) isExcluded = '?';

		if (map.type === 'object' && !map.parentRef) {
			propsCode += `
				${map.name}: {${generateInterfaceProps(
				app,
				mapData.filter(x => x.parentRef === map.ref),
				true,
				mapData,
			)}
				};`;
		} else if (enableParent || !map.parentRef) {
			if (map.type === 'object') {
				propsCode += `
					${map.name}: {${generateInterfaceProps(
					app,
					mapNoFilter?.filter(x => x.parentRef === map.ref),
					true,
					mapNoFilter,
				)}
				};`;
			} else if (map.type === 'array') {
				const tableOrigin = app.database?.tables?.find(x => x.ref === map?.subtype)?.name;
				const datatype = formatScreenName(tableOrigin!);
				propsCode += `${map.name}: I${datatype}[];`;
			} else if (map.type === 'file') {
				propsCode += `${map.name}: any;`;
			} else {
				propsCode += `${map.name}${isExcluded}: ${map.type};`;
			}
		}
	});

	return propsCode;
};

const generateInterfaces = (apis: IApi[], app: IApplication): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];
	const customDataType: string[] = [];

	apis.forEach(api => {
		let code = '';

		api.endpoints?.forEach(endpoint => {
			if (
				endpoint.method != ApiMethods.DELETE &&
				!endpoint.name.toLocaleLowerCase().includes('odata')
			) {
				let interfaceCode = generateInterfaceProps(app, endpoint.request);
				if (interfaceCode && interfaceCode.trim() != '') {
					code += getInterfaceTemplate(api, endpoint);
					code = code.replace('{INTERFACE_DATA}', interfaceCode);

					const originTableRef = endpoint.request?.find(x => x.type === 'array')?.subtype;

					if (originTableRef !== undefined) {
						const tableOrigin = app.database?.tables?.find(
							x => x.ref === originTableRef,
						)?.name;
						const datatype = formatScreenName(tableOrigin!);
						const endpointOrigin = api.endpoints?.find(
							x => x.databaseTableRef === originTableRef,
						);
						const interfaceName = `I${datatype}`;

						if (customDataType.some(x => x === interfaceName) === false) {
							interfaceCode = generateInterfaceProps(app, endpointOrigin?.request);
							if (interfaceCode && interfaceCode.trim() != '') {
								code += getInterfaceTemplate(
									api,
									endpointOrigin!,
									undefined,
									interfaceName,
								);
								code = code.replace('{INTERFACE_DATA}', interfaceCode);

								customDataType.push(interfaceName);
							}
						}
					}
				}
			}
		});

		api.endpoints?.forEach(endpoint => {
			if (endpoint.method != ApiMethods.DELETE) {
				if (endpoint.response && endpoint.response.length > 0) {
					code += getInterfaceTemplate(api, endpoint, 'result');
					const interfaceCode = generateInterfaceProps(app, endpoint.response);
					code = code.replace('{INTERFACE_DATA}', interfaceCode);
				}
			}
		});

		code = prettier.format(code, PrettierConfig);

		resourceApi.push({
			code,
			name: `${api.name?.toLocaleLowerCase()}-model.ts`,
			path: 'src/models/',
		});
	});

	return resourceApi;
};

const getBaseName = (api: IApi) => `Api${upperFirstWord(api.name ?? '')}`;

const getInterfaceName = (api: IApi, data: IApiEndpoint) =>
	`I${upperFirstWord(api.name ?? '')}${upperFirstWord(data.name)}`;

const repositoryTemplate = (api: IApi) => `
	/* eslint-disable @typescript-eslint/no-explicit-any */
	import {${getBaseName(api)}} from './base';
	import {{IMPORTS_DATA}} from 'src/models/${api.name?.toLocaleLowerCase()}-model';

	export class ${getRepositoryClass(api.name ?? '')} {{CODE_DATA}}
`;

const constructMethod = (typeReturn: string, api: IApi, endpoint: IApiEndpoint, method: string) => {
	const codeBase = `await ${getBaseName(api)}.${endpoint.method}`;

	if (endpoint.name.toLocaleLowerCase().includes('odata')) {
		return `
			const result = ${codeBase}${method};
			return result.data;
		`;
	}
	if (typeReturn === 'void') {
		return `${codeBase}${method};`;
	} else {
		return `
			const result = ${codeBase}${method};
			return result.data;
		`;
	}
};

const splitKey = (url: string, data?: string[]): string[] => {
	if (!data) data = [];
	const openIdx = url.indexOf('{');
	const closeIdx = url.indexOf('}');

	if (openIdx != -1 && closeIdx != -1) {
		const key = url.substring(openIdx + 1, closeIdx);
		data.push(key);
		url = url.replace(`{${key}}`, '');

		data = splitKey(url, data);
	}

	return data;
};

const getName = (apiName: string): string => {
	if (apiName && apiName.trim() != '') return apiName.toLocaleLowerCase();
	return 'default';
};
export const getRepositoryName = (apiName: string) => `${getName(apiName)}-repository`;
export const getRepositoryClass = (apiName: string) => `${getName(apiName)}Repository`;

const getInterfaceTemplate = (
	api: IApi,
	endpoint: IApiEndpoint,
	type: 'default' | 'result' = 'default',
	customName?: string | undefined,
) => {
	const interfaceName = customName === undefined ? getInterfaceName(api, endpoint) : customName;

	return `
		export interface ${interfaceName}${type === 'result' ? 'Result' : ''} {{INTERFACE_DATA}
		}
		`;
};

const hasRequestParam = (endpoint: IApiEndpoint): boolean => (endpoint.request ? true : false);
